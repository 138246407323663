import { useTranslation } from "../context/Internationalization";
import { axiosGraphQL } from "../api";

const useGraphql = () => {
  const { t } = useTranslation();

  const handleGraphqlErrors = (errors, specificErrorMessage = null) => {
    if (!errors || errors.length === 0) {
      throw new Error(`${t("error")} ${t("an_error_occurred")}`);
    }

    if (specificErrorMessage) {
      throw new Error(`${t("error")} ${specificErrorMessage}`);
    }

    let concatErrorMessages = errors
      .map((error) => {
        let errorObject = null;
        try {
          errorObject = JSON.parse(error.message);
        } catch (err) {
          errorObject = { message: error.message };
        }
        return t(errorObject.message) || t("an_error_occurred");
      })
      .join(",\n");

    const errorMessage = `${t(
      errors.length === 1 ? "error" : "errors"
    )} ${concatErrorMessages}`;
    throw new Error(errorMessage);
  };

  const handleGraphql = (queryObject) =>
    axiosGraphQL(queryObject)
      .then((response) => {
        // The request was made and the server responded with a status code 2xx,
        // but the graphql query returned errors
        if (response.data.errors) {
          throw response.data.errors;
        }

        return response;
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.data.errors) {
            handleGraphqlErrors(error.response.data.errors);
          }
        } else if (error.request) {
          // The request was made but no response was received
          handleGraphqlErrors(error, t("error_server_not_responding"));
        } else {
          // Something happened in setting up the request that triggered an Error
          handleGraphqlErrors(error);
        }
      });

  return { axiosGraphQL: handleGraphql, handleGraphqlErrors };
};

export default useGraphql;
