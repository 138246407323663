import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {TableCell, TableRow} from "@mui/material";


const TeamsTableRow = ({ data = {}}) => {
  const isTeamAdmin = data?.teamAdminUsers.find(admin => admin === data.userId)

  return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            {data.name}
          </TableCell>
          <TableCell>{data.club?.name}</TableCell>
          <TableCell>{data.teamCode}</TableCell>
          <TableCell>{isTeamAdmin ? 'Team-admin' : 'Coach'}</TableCell>
        </TableRow>
  );
};

TeamsTableRow.propTypes = {};

export default TeamsTableRow;
