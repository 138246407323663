import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import useStyles from "./modalStyles";
import DeleteModalIcon from "../../assets/images/Svg/DeleteModalIcon";
import VerificationIcon from "../../assets/images/payment-icon/verification-icon.svg";

const ModalHeader = ({type, onClose}) => {
    const styles = useStyles()
    let Icon = DeleteModalIcon

    if(type === 'info') {
        Icon = () =>  <img src={VerificationIcon} alt={'info-icon'}/>
    }
    return (
        <Stack className={styles.modalHeader} direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <div style={{marginLeft: 'calc(50% - 32px)'}}>
                <Icon/>
            </div>
            <IconButton className={styles.modalCloseButton} onClick={onClose}>
                <CloseIcon/>
            </IconButton>
        </Stack>
    );
};

ModalHeader.propTypes = {

};

export default ModalHeader;
