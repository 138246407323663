import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Replay } from "@sentry/browser";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { playerDexTheme } from "./theme/theme";
import reportWebVitals from "./reportWebVitals";
import { store } from './redux/store'
import { Provider } from 'react-redux'

Sentry.init({
  dsn: "https://804a1f19998043468017a246194e434f@o1158366.ingest.sentry.io/6733464",
  integrations: [new BrowserTracing(), new Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.3,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={playerDexTheme}>
        <Provider store={store}>
             <App />
        </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
