import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from "../context/AuthContext";
import {GET_INVITATIONS} from "../graphql/invitationGraphql";
import { SnackBarContext } from './SnackBarContext';
import useGraphql from "../Hooks/useGraphql";
import useLog from "../Hooks/useLog";

const InvitationContext = createContext();
const { Provider } = InvitationContext;

const InvitationProvider = ({ children }) => {
    const auth = useAuthContext();
    const snackBar = useContext(SnackBarContext);
    const [invitations, setInvitations] = useState([])
    const {axiosGraphQL} = useGraphql()
    const {errorLog} = useLog();

    useEffect(() => {
        if(auth?.authState?.token) getInvitations();
    }, [auth])

    const getInvitations = () => {
        return axiosGraphQL({
            query: GET_INVITATIONS,
            variables: {limit: 100},
        }).then(response => {
            const resInvitations = response?.data?.data?.invitations?.filter(
                invitation => invitation?.status === 'pending',
            );
            setInvitations(resInvitations)
            return resInvitations
        }).catch((error) => {
            errorLog(error, 'InvitationContext', 'useEffect', 'GET_INVITATIONS');
            snackBar.setOptions({
                alertMessage: error.message,
                alertSeverity: "error",
                });
            snackBar.setSnackbarOpen(true);
        });
    }

    return (
        <Provider value={{invitations, getInvitations}}>
            {children}
        </Provider>
    );
};

export const useInvitations = () => {
    return useContext(InvitationContext)
}


InvitationProvider.propTypes = {

};

export default InvitationProvider;
