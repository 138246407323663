export const RSVP = `
  fragment rsvpFields on RSVP {
    _id
    user
    profile
    teamId
    clubId
    eventGroupId
    eventId
    isGoing
    message
    isGuest
    createdAt
    updatedAt
  }
`;

export const MATCH_STRENGTH_AND_WEAKNESSES = `
  fragment matchStrengthAndWeaknessesFields on MatchStrengthAndWeeknesses {
    transition
    setPiecies
    duels
    creativity
    physical
    tactic
  } 
`;

export const TEAM_EVALUATION = `
  fragment teamEvaluationFields on TeamEvaluation {
     attacking
     defending
  }
`;

export const MATCH_REPORT = `
  ${TEAM_EVALUATION}
  ${MATCH_STRENGTH_AND_WEAKNESSES}
  fragment matchReportFields on MatchReport {
    _id
    eventId
    eventType
    homeScore
    awayScore
    matchFormat
    matchDuration
    formation
    teamEvaluation {
     ...teamEvaluationFields
    }
    matchStrengthAndWeeknesses {
      ...matchStrengthAndWeaknessesFields
    }
  }
`;

export const EVENT_FIELDS = `
  ${RSVP}
  ${MATCH_REPORT}
  fragment eventFields on EventWithRSVP {
    _id
    club
    team
    opponentTeam
    opponentClub
    leagueFixture
    fromTime
    toTime
    homeMatch
    meetTime
    timezone
    location {
      address
      coordinates
      type
    }
    eventType
    title
    isCancelled
    isUpdated
    createdBy
    groupId
    eventTeams
    rsvps {
      ...rsvpFields
    }
    matchReport {
      ...matchReportFields
    }
    metadata {
      trainingType
      scheduleType
    }
  }
`;

export const CREATE_TRAINING_EVENT = `
  mutation CreatePostTraining(
  $inviteAllPlayers: Boolean!, 
  $team: String!, 
  $schedule: String!, 
  $trainingType: String!, 
  $fromTime: String!, 
  $toTime: String!, 
  $invitedPlayers: [String], 
  $guestsInvited: [String], 
  $club: String, 
  $timezone: String, 
  $location: LocationInput, 
  $post: PostInput, $title: String) {
    createTraining(inviteAllPlayers: $inviteAllPlayers,
    team: $team,
    schedule: $schedule,
    trainingType: $trainingType,
    fromTime: $fromTime,
    toTime: $toTime,
    invitedPlayers: $invitedPlayers,
    guestsInvited: $guestsInvited,
    club: $club,
    timezone: $timezone,
    location: $location,
    post: $post, title: $title) {
        eventId
        groupId
    }
  }
`;

export const CREATE_MATCH_EVENT = `
  mutation CreatePostMatch(
  $inviteAllPlayers: Boolean!,
  $opponentClub: String,
  $team: String!, 
  $opponentTeam: String, 
  $homeMatch: Boolean!, 
  $fromTime: String!, 
  $toTime: String!, 
  $invitedPlayers: [String], 
  $guestsInvited: [String], 
  $club: String,
  $leagueFixture: String,
  $title: String,
  $timezone: String,
  $location: LocationInput, 
  $post: PostInput) {
    createMatch(inviteAllPlayers: $inviteAllPlayers,
    opponentClub: $opponentClub,
    team: $team,
    opponentTeam: $opponentTeam,
    homeMatch: $homeMatch,
    fromTime: $fromTime,
    toTime: $toTime,
    invitedPlayers: $invitedPlayers,
    guestsInvited: $guestsInvited,
    club: $club,
    timezone: $timezone,
    leagueFixture: $leagueFixture,
    location: $location,
    title: $title,
    post: $post) 
  }
`;

export const CREATE_EVENT_EVENT = `
  mutation CreateEventEvent(
  $club: String,
  $eventType: String!,
  $trainingType: String, 
  $fromTime: String!, 
  $toTime: String!, 
  $title: String,
  $text: String,
  $notes: String, 
  $meetTime: String,
  $location: LocationInput!, 
  $post: PostInput,
  $userList: [String],
  $team: String,
   $timezone: String,
  $eventTeams: [String],
  $profileList: [String]) {
  createEvent( 
  club:  $club,
  eventType: $eventType,
  trainingType: $trainingType, 
  fromTime: $fromTime, 
  toTime: $toTime, 
  title: $title,
  text: $text,
  notes: $notes, 
  meetTime: $meetTime,
  location: $location, 
  post: $post,
  userList: $userList,
  team: $team,
  timezone: $timezone,
  eventTeams: $eventTeams,
  profileList: $profileList) {
   _id
  }
  
  }
`;

export const UPDATE_EVENT = `
  mutation updateActivity(
  $groupId: String!, 
  $eventId: String!, 
  $trainingType: String, 
  $fromTime: String, 
  $toTime: String, 
  $title: String,
  $meetTime: String, 
  $location: LocationInput,
  $updateNextEvents: Boolean!,
  $postText: String,
  $postTitle: String,
  $postNotes: String,
  $disablePost: Boolean) {
    updateEvent(groupId: $groupId,
    eventId: $eventId,
    trainingType: $trainingType,
    fromTime: $fromTime,
    toTime: $toTime,
    title: $title,
    meetTime: $meetTime,
    location: $location,
    updateNextEvents: $updateNextEvents,
    postText: $postText,
    postTitle: $postTitle,
    postNotes: $postNotes,
    disablePost: $disablePost) {
      _id
    }
  }
`;

export const CANCEL_EVENT = `
  mutation cancelActivity(
    $groupId: String!, 
    $eventId: String!, 
    $cancelNextEvents: Boolean, 
    $text: String) {
      cancelEvent(
        groupId: $groupId,
        eventId: $eventId,
        cancelNextEvents: $cancelNextEvents,
        text: $text
      )
  }
`;

export const DELETE_EVENT = `
  mutation deleteActivity(
    $groupId: String!, 
    $eventId: String!, 
    $deleteNextEvents: Boolean!) {
      deleteEvent(
        groupId: $groupId,
        eventId: $eventId,
        deleteNextEvents: $deleteNextEvents
      )
  }
`;

export const ADD_REMOVE_RSVP = `
   mutation (
    $eventId: String!,
    $profileId: String,
    $userId: String,
    $isGuest: Boolean!, 
    $removeRSVP: Boolean!,
    $club: String) {
      addRemoveRSVP(
        eventId: $eventId,
        profileId: $profileId,
        isGuest: $isGuest,
        userId: $userId,
        removeRSVP: $removeRSVP,
        club: $club
      )
  }
`;

export const GET_EVENTS = `
  ${EVENT_FIELDS}
  query GetEvents($teamId: String!, $clubId: String, $eventType: String, $fromDate: String, $toDate: String, $skip: Int = 0, $limit: Int = 20, $sortingDir: String, $sortingField: String) {
    events(teamId: $teamId, clubId: $clubId, eventType: $eventType, fromDate: $fromDate, toDate: $toDate, skip: $skip, limit: $limit, sortingDir: $sortingDir, sortingField: $sortingField) {
      ...eventFields
    }
  }
`;

export const GET_EVENT = `
  ${EVENT_FIELDS}
  query GetEvent($id: String, $groupId: String) {
    event(id: $id, groupId: $groupId) {
      ...eventFields
    }
  }
`;

export const prepareMatchForSubmit = (formValues) => {
  return {
    inviteAllPlayers: formValues.inviteAllPlayers,
    invitedPlayers: formValues.inviteAllPlayers
      ? []
      : formValues.invitedPlayers
          .filter((player) => player.selected)
          .map((p) => p._id),
    guestsInvited: [],
    opponentClub: formValues.opponentClub || null,
    opponentTeam: formValues.opponentTeam || null,
    team: formValues.team,
    club: formValues.clubId,
    homeMatch: formValues.homeMatch,
    title: formValues.title,
    date: `${formValues.date.toFormat("y/LL/dd")}`,
    fromTime: `${formValues.date.toFormat(
      "y/LL/dd"
    )} ${formValues.fromTime.toFormat("HH:mm")}`,
    toTime: `${formValues.date.toFormat(
      "y/LL/dd"
    )} ${formValues.toTime.toFormat("HH:mm")}`,
    location: formValues.location
      ? {
          type: "Point",
          address: formValues.location.address,
          coordinates: [formValues.location.lng, formValues.location.lat],
        }
      : null,
    post: formValues.post
      ? {
          text: formValues.postText,
          club: formValues.clubId,
          teams: [formValues.team],
          postType: formValues.postType,
        }
      : null,
    trainingType: formValues.trainingType,
  };
};

export const prepareTrainingForSubmit = (formValues) => {
  return {
    inviteAllPlayers: formValues.inviteAllPlayers,
    invitedPlayers: formValues.inviteAllPlayers
      ? []
      : formValues.invitedPlayers
          .filter((player) => player.selected)
          .map((p) => p._id),
    guestsInvited: [],
    schedule: formValues.schedule ? "weekly" : "oneOf",
    team: formValues.team,
    club: formValues.clubId,
    title: formValues.title,
    date: `${formValues.date.toFormat("y/LL/dd")}`,
    fromTime: `${formValues.date.toFormat(
      "y/LL/dd"
    )} ${formValues.fromTime.toFormat("HH:mm")}`,
    toTime: `${formValues.date.toFormat(
      "y/LL/dd"
    )} ${formValues.toTime.toFormat("HH:mm")}`,
    location: formValues.location
      ? {
          type: "Point",
          address: formValues.location.address,
          coordinates: [formValues.location.lng, formValues.location.lat],
        }
      : null,
    post: formValues.post
      ? {
          text: formValues.postText,
          club: formValues.clubId,
          teams: [formValues.team],
          postType: formValues.postType,
        }
      : null,
    trainingType: formValues.trainingType,
  };
};

export const prepareEventForUpdate = (formValues, selectedActivity) => {
  const event = {
    groupId: selectedActivity.groupId,
    eventId: selectedActivity._id,
    fromTime: `${formValues.fromTime.toFormat(
      "y/LL/dd"
    )} ${formValues.fromTime.toFormat("HH:mm")}`,
    toTime: `${formValues.toTime.toFormat(
      "y/LL/dd"
    )} ${formValues.toTime.toFormat("HH:mm")}`,
    title: formValues.title,
    location: formValues.location
      ? {
          type: "Point",
          address: formValues.location.address,
          coordinates: [formValues.location.lng, formValues.location.lat],
        }
      : null,
    updateNextEvents:
      selectedActivity.eventType === "Training"
        ? Boolean(formValues.updateNextEvents)
        : false,
    postText: formValues.postText,
    postTitle: formValues.postTitle,
  };

  if (selectedActivity.eventType.toLowerCase() === "event") {
    event.meetTime = formValues.meetTime;
  }

  if (selectedActivity.eventType.toLowerCase() === "training") {
    event.trainingType = formValues.trainingType;
  }

  return event;
};

const RSVP_FIELDS = `
fragment rsvpFields on RSVP {
    _id
    user
    profile
    eventGroupId
    eventId
    isGoing
    message
    isGuest
    createdAt
    updatedAt
}
`;

export const REPLY_TO_RSVP = `
  ${RSVP_FIELDS}
  mutation ReplyToRSVP($id: String!, $going: Boolean!, $message: String, $resetIsGoing: Boolean, $resetMessage: Boolean) {
    replyToRsvp(id: $id, going: $going, message: $message, resetIsGoing: $resetIsGoing, resetMessage: $resetMessage) {
      ...rsvpFields
    }
  }
`;

const NOTE_TUPPLE_FIELDS = `
fragment noteTuppleFields on NoteTupple {
    _id
    teamId
    refId
    note
}
`;

export const CREATE_NOTE_TUPPLE = `
  mutation CreateNoteTupple($teamId: String, $refId: String, $note: String) {
    createNoteTupple(teamId: $teamId, refId: $refId, note: $note) {
      _id
    }
  }
`;

export const DELETE_NOTE_TUPPLE = `
  mutation DeleteNoteTupple($id: String) {
    deleteNoteTupple(id: $id) {
      _id
    }
  }
`;

export const UPDATE_NOTE_TUPPLE = `
  mutation UpdateNoteTupple($teamId: String, $refId: String, $note: String) {
    updateNoteTupple(teamId: $teamId, refId: $refId, note: $note) {
      _id
    }
  }
`;
export const GET_NOTE_TUPPLE = `
  ${NOTE_TUPPLE_FIELDS}
  query GetNoteTupple($teamId: String, $refId: String) {
    getNoteTupple(teamId: $teamId, refId: $refId) {
      ...noteTuppleFields
    }
  }
`;
