import React, {lazy} from "react";
import {Routes, Route} from "react-router-dom";
import Box from "@mui/material/Box";

const Teams = lazy(() => import("../Teams/Teams"));
const Fields = lazy(() => import("../Fields/Fields"));
const Forms = lazy(() => import("../Forms/Forms"));

export const Club = () => {
    return (
        <Box component="main" sx={{flexGrow: 1, p: 3}}>
            <Routes>
                <Route path="/teams/*" element={<Teams/>}/>
                <Route path="/fields" element={<Fields/>}/>
                <Route path="/forms" element={<Forms/>}/>
            </Routes>
        </Box>
    );
};

export default Club;
