import { EVENT_FIELDS } from "../../graphql/eventGraphql";
import { USER_FIELDS } from "../../graphql/userGraphql";

export const POST_FIELDS = `
  ${EVENT_FIELDS}
  fragment postFields on PostWithFirstEvent {
    _id
    text
    photos
    club
    postType
    teams
    isUpdated
    eventGroupId
    firstEvent {
      ...eventFields
    }
    createdBy
    createdAt
    updatedAt
    reactions {
      postId
      userId
      reactionType
    }
  }
`;

export const GET_CLUBS_SA = `
query($term: String, $skip: Int, $limit: Int) {
  getClubsSA(term: $term, skip: $skip, limit: $limit) {
    _id
    name
    logo
    clubAdminUsers
    location {
      address
      coordinates
    }
    founded
    country
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isOfficial
    isPremium
  }
}`;

export const IS_PREMIUM = `
mutation ToggleIsPremiumSA($clubId: String!, $isPremium: String!) {
  toggleIsPremiumSA(clubId: $clubId, isPremium: $isPremium) {
    _id
    name
    logo
    location {
      address
      coordinates
      type
    }
    founded
    clubAdminUsers
    country
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isOfficial
    isPremium
  }
}
`;

export const EDIT_CLUB_SA = `
mutation editClubSA(
  $clubId: String!
  $name: String
  $founded: Int
  $country: String
  $isPremium: String
  ) {
  editClubSA(
    clubId: $clubId
    name: $name
    founded: $founded
    country: $country
    isPremium: $isPremium
  ) {
    _id
    name
    logo
    location {
      address
      coordinates
      type
    }
    founded
    clubAdminUsers
    country
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isOfficial
    isPremium
  }
}
`;

export const CREATE_CLUB = `
  mutation CreateClub($name: String!, $founded: Int, $country: String!, $contactInfo: ContactInfoInput, $location: LocationInput, $leagues: [String]) {
    createClub(name: $name, founded: $founded, country: $country, contactInfo: $contactInfo, location: $location, leagues: $leagues) {
      _id
    }
  }
`;

export const REGISTER_USER_SA = `
 mutation RegisterUserSa($firstName: String!, $lastName: String, $mobile: String!, $role: String!, $clubId: String, $teamId: String) {
    RegisterUserSa(firstName: $firstName, lastName: $lastName, mobile: $mobile, role: $role, clubId: $clubId, teamId: $teamId) {
      _id
    }
  }
`;

export const REMOVE_CLUB_ADMIN_SA = `
 mutation RemoveClubAdmin($clubId: String, $userId: String, $deleteUser: Boolean) {
    removeClubAdmin(clubId: $clubId, userId: $userId, deleteUser: $deleteUser)
  }
`;

export const OFFICIAL_LEAGUE = `
  fragment officialLeague on OfficialLeagueQlType {
	_id
	logo
	name
	description
	website
	email
	country
	isOfficial
	sport
	connected_clubs
	connected_teams
	createdAt
	updatedAt
  }
`;

export const OFFICIAL_LEAGUE_COMPETITION = `
  fragment officialLeagueCompetition on OfficialCompetitionModelInterfaceQlType {
  _id
	type
	ageGroup
	gender
	name
	format
	startYear
	location {
		type
		address
		coordinates
	}
	clubsInCompetition
	teamsInCompetition
}`;

export const OFFICIAL_LEAGUE_FIXTURE = `
  fragment officialLeagueFixture on OfficialFixtureQlType {
	_id
	fixtureName
	leagueId
	competitionId
	createdAt
	updatedAt
  }
`;

export const OFFICIAL_LEAGUE_FIXTURE_MATCH = `
  fragment officialLeagueFixtureMatch on OfficialFixtureMatchQlType {
    _id
	homeTeam
	awayTeam
	date
	time
	venue
	location {
		type
		address
		coordinates
	}
	status
	score
	competitionId
	fixtureId
	leagueId
	createdAt
	updatedAt
  }
`;

export const CREATE_OFFICIAL_LEAGUE_SA = `
mutation CreateOfficialLeague(
  $name: String
  $description: String
  $logo: String
  $website: String
  $email: String
  $country: String
  $sport: String
  $connected_clubs: [String]
  $connected_teams: [String]
  $competitions: [OfficialCompetitionWithFixturesAndMatchesInputQlType]
) {
  createOfficialLeague(
    name: $name
    description: $description
    logo: $logo
    website: $website
    email: $email
    country: $country
    sport: $sport
    connected_clubs: $connected_clubs
    connected_teams: $connected_teams
    competitions: $competitions
  ) {
    league {
      _id
      logo
      name
      description
      website
      email
      country
      sport
      connected_clubs
      connected_teams
      createdAt
      updatedAt
    }
    competitions {
      _id
      leagueId
      type
      ageGroup
      gender
      name
      format
      startYear
      location {
        type
        address
        coordinates
      }
      clubsInCompetition
      teamsInCompetition
    }
    fixtures {
      _id
      fixtureName
      leagueId
      competitionId
      createdAt
      updatedAt
    }
    matches {
      _id
      leagueId
      competitionId
      fixtureId
      homeTeam
      awayTeam
      date
      time
      venue
      location {
        type
        address
        coordinates
      }
      status
      score
    }
  }
}
`;

export const GET_OFFICIAL_LEAGUES_SA = `
query($clubId: String) {
  getOfficialLeagues(clubId: $clubId) {
    league {
      _id
      logo
      name
      description
      website
      email
      country
      sport
      isOfficial
      connected_clubs
      connected_teams
      createdAt
      updatedAt
    }
    competitions {
      _id
      leagueId
      type
      ageGroup
      gender
      name
      format
      startYear
      location {
        type
        address
        coordinates
      }
      clubsInCompetition
      teamsInCompetition
      createdAt
      updatedAt
      fixtures {
        _id
        fixtureName
        leagueId
        competitionId
        createdAt
        updatedAt
        matches {
          _id
          leagueId
          competitionId
          fixtureId
          homeTeam
          awayTeam
          date
          time
          venue
          location {
            type
            address
            coordinates
          }
          status
          score
          createdAt
          updatedAt
        }
      }
    }
  }
}`;

export const GET_OFFICIAL_LEAGUE_COMPETITIONS_SA = `
 ${OFFICIAL_LEAGUE_COMPETITION}
  query ($leagueId: String){
   GetOfficialLeagueCompetitions(leagueId: $leagueId) {
      ...officialLeagueCompetition
    }
}`;

export const GET_OFFICIAL_LEAGUE_FIXTURES_SA = `
 ${OFFICIAL_LEAGUE_FIXTURE}
  query ($leagueId: String, $competitionId: String){
   getOfficialLeagueFixtures(leagueId: $leagueId, competitionId: $competitionId) {
      ...officialLeagueFixture
    }
}`;

export const GET_OFFICIAL_LEAGUE_MATCHES_SA = `
 ${OFFICIAL_LEAGUE_FIXTURE_MATCH}
 query($leagueId: String, $competitionId: String, $fixtureId: String) {
  getOfficialLeagueMatches(
    leagueId: $leagueId
    competitionId: $competitionId
    fixtureId: $fixtureId
  ) {
    ...officialLeagueFixtureMatch
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_SA = `
mutation EditOfficialLeague(
  $leagueId: String
  $logo: String
  $name: String
  $description: String
  $website: String
  $email: String
  $country: String
  $sport: String
  $connected_clubs: [String]
  $connected_teams: [String]
) {
  editOfficialLeague(
    leagueId: $leagueId
    logo: $logo
    name: $name
    description: $description
    website: $website
    email: $email
    country: $country
    sport: $sport
    connected_clubs: $connected_clubs
    connected_teams: $connected_teams
  ) {
    _id
    logo
    name
    description
    website
    email
    country
    sport
    isOfficial
    connected_clubs
    connected_teams
    createdAt
    updatedAt
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_COMPETITION_SA = `
mutation EditOfficialLeagueCompetition(
  $_id: String
  $type: String
  $ageGroup: String
  $gender: String
  $name: String
  $startYear: String
  $format: String
  $location: LocationInput
  $connected_clubs: [String]
  $connected_teams: [String]
) {
  EditOfficialCompetitionSA(
    _id: $_id
    type: $type
    ageGroup: $ageGroup
    gender: $gender
    name: $name
    startYear: $startYear
    format: $format
    location: $location
    connected_clubs: $connected_clubs
    connected_teams: $connected_teams
  ) {
    _id
    leagueId
    type
    ageGroup
    gender
    name
    format
    startYear
    location {
      type
      address
      coordinates
    }
    clubsInCompetition
    teamsInCompetition
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_FIXTURE_SA = `
mutation EditOfficialLeagueFixture($fixtureId: String, $fixtureName: String) {
  editOfficialFixture(fixtureId: $fixtureId, fixtureName: $fixtureName) {
    _id
    fixtureName
    leagueId
    competitionId
    createdAt
    updatedAt
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_FIXTURE_MATCH_SA = `
mutation EditOfficialLeagueFixtureMatch(
  $matchId: String
  $homeTeam: String
  $awayTeam: String
  $date: String
  $time: String
  $venue: String
  $status: String
  $score: String
  $location: LocationInput
) {
  editOfficialFixtureMatch(
    matchId: $matchId
    homeTeam: $homeTeam
    awayTeam: $awayTeam
    date: $date
    time: $time
    venue: $venue
    status: $status
    score: $score
    location: $location
  ) {
    _id
    leagueId
    competitionId
    fixtureId
    homeTeam
    awayTeam
    date
    time
    venue
    location {
      type
      address
      coordinates
    }
    status
    score
    createdAt
    updatedAt
  }
}`;

const PROFILE_WITH_INVITATIONS = `
    fragment profileWithInvitation on ProfileWithInvitations {
     _id
    firstName
    lastName
    mobile
    club 
    team 
    yearOfBirth
    userId
    isActive
    parents
    invitations {
         _id
        fromId
        invitationType
        invitationToType
        toId
        status
        context { 
                message
                itemId
                itemType
        }
     }
    }
`;
export const GET_USER_PROFILES_SA = `
 ${PROFILE_WITH_INVITATIONS}
  query GetClubProfilesSA($userId: String){
   getClubProfilesSA(userId:  $userId) {
      ...profileWithInvitation
    }
}`;

export const RESOLVE_CLUB = `
  query ($ids: [String]){
   ResolveClubs(ids:  $ids) {
      name
    }
}`;

export const USER_WITH_INVITATIONS_FIELDS = `
  fragment userFields on UserWithInvitations {
    _id
    mobile
    firstName
    lastName
    roles
    invitations {
         _id
        fromId
        invitationType
        invitationToType
        status
        toId
        context { 
                message
                itemId
                itemType
        }
    }
    clubs {
       name
       _id
       isOfficial
       isPremium
     }
    teams {
     _id
     name
     club {
       name
       _id
       isOfficial
       isPremium
     }
     teamAdminUsers
     coaches
     teamCode
    }
  }
`;

export const SEARCH_USER_WITH_MOBILE_SA = `
 ${USER_WITH_INVITATIONS_FIELDS}
  query SearchUserWithMobileSA($mobile: String!) {
    searchUserWithMobileSA(mobile: $mobile) { 
      ...userFields
    }
  } 
`;

export const UPLOAD_LEAGUE_IMAGE = `
  mutation ($uploadType: String!, $itemId: String!, $fileName: String!) {
  uploadImage(uploadType: $uploadType, itemId: $itemId, fileName: $fileName)
}
`;