import { EVENT_FIELDS } from "../../graphql/eventGraphql";

export const POST_FIELDS = `
  ${EVENT_FIELDS}
  fragment postFields on PostWithFirstEvent {
    _id
    text
    photos
    club
    postType
    teams
    isUpdated
    eventGroupId
    firstEvent {
      ...eventFields
    }
    createdBy
    createdAt
    updatedAt
    notes
    reactions {
      postId
      userId
      reactionType
    }
  }
`;

export const GET_POSTS = `
  ${POST_FIELDS}
  query GetPosts($teamId: String!, $clubId: String, $skip: Int = 0, $limit: Int = 20) {
    posts(teamId: $teamId, clubId: $clubId, skip: $skip, limit: $limit) {
      ...postFields
    }
  }
`;

export const GET_POSTS_BY_TYPE = `
  ${POST_FIELDS}
  query GetPostsByType($teamId: String!, $clubId: String, $postType: String, $skip: Int = 0, $limit: Int = 20) {
    posts(teamId: $teamId, clubId: $clubId, postType: $postType, skip: $skip, limit: $limit) {
      ...postFields
    }
  }
`;

export const CREATE_POST = `
  mutation CreatePost($post: PostInput) {
    createPost(post: $post) {
      _id
    }
  }
`;

export const UPDATE_POST = `
  mutation ($id: String, $text: String) {
    editPost(id: $id, text: $text) {
      _id
    }
  }
`;

export const DELETE_POST = `
  mutation ($id: String!) {
    deletePost(id: $id)
  }
`;

export const GET_CLUB_POSTS = `
  ${POST_FIELDS}
  query ($clubId: String, $skip: Int, $limit: Int, $teamId: String!, $postType: String, $sortingDir: String, $sortingField: String, $fromDate: String, $toDate: String) {
    posts(teamId: $teamId, clubId: $clubId, skip: $skip, limit: $limit, postType: $postType, sortingDir: $sortingDir, sortingField: $sortingField, fromDate: $fromDate, toDate: $toDate) {
      ...postFields
    }
  
  }
 `;

export const GET_CLUB_TEAMS = `
  query ($clubId: String!, $teamsSkip: Int, $teamsLimit: Int) {
   ClubTeams(clubId: $clubId, skip: $teamsSkip, limit: $teamsLimit) {
      _id
      name
      ageGroup
      teamCode
      sport
      club
    }
}
`;

export const preparePostForSubmit = (formValues) => {
  const teams = formValues.teams
    .filter((team) => team.selected)
    .map((t) => t._id);
  return {
    title: formValues.title,
    text: formValues.message,
    club: formValues.clubId,
    postType: formValues.postType,
    teams,
  };
};
