export const CLUB_FIELDS = `
  fragment clubFields on Club {
    _id
    name
    logo
    location {
      address
      coordinates
      type
    }
    founded
    country
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isOfficial
    isPremium
  }
`;
