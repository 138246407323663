import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { generateLeagueCompetitions } from "../../../utility/Utils";
import { FILE_TYPES } from "../../../utility/Enum";
import update from "immutability-helper";
import { axiosGraphQL } from "../../../api";
import {
  GET_CLUBS_SA,
  CREATE_OFFICIAL_LEAGUE_SA,
  EDIT_OFFICIAL_LEAGUE_SA,
  EDIT_OFFICIAL_LEAGUE_COMPETITION_SA,
  EDIT_OFFICIAL_LEAGUE_FIXTURE_SA,
  EDIT_OFFICIAL_LEAGUE_FIXTURE_MATCH_SA,
  GET_OFFICIAL_LEAGUE_COMPETITIONS_SA,
  GET_OFFICIAL_LEAGUE_FIXTURES_SA,
  GET_OFFICIAL_LEAGUE_MATCHES_SA,
  UPLOAD_LEAGUE_IMAGE,
} from "../SuperAdminService";

const initialState = {
  loading: false,
  formsValidationTriggered: false,
  renderMatchesTrigger: false,
  league: {
    _id: uuidv4(),
    name: "",
    sport: "football",
    description: "",
    website: "",
    email: "",
    country: "Greece",
    connected_clubs: [],
    connected_teams: [],
    errors: {},
  },
  competitions: [
    {
      _id: uuidv4(),
      name: "",
      format: "11x11",
      type: "league",
      ageGroup: "",
      gender: "",
      startYear: `${new Date().getFullYear()}`.substring(2),
      location: {},
      clubsInCompetition: [],
      teamsInCompetition: [],
      fixtures: [
        {
          _id: uuidv4(),
          fixtureName: "",
          leagueId: "",
          competitionId: "",
          matches: [
            {
              _id: uuidv4(),
              homeTeam: "",
              awayTeam: "",
              date: null,
              time: null,
              timezone: "Europe/Athens",
              status: "pending",
              score: "",
              venue: "",
              location: {},
              competitionId: "",
              fixtureId: "",
              leagueId: "",
              errors: {},
            },
          ],
          errors: {},
        },
      ],
      errors: {},
    },
  ],
};

// AsyncThunk: createOfficialLeague
export const createOfficialLeague = createAsyncThunk(
  "officialLeague/createOfficialLeague",
  async (arg, { getState }) => {
    const state = getState().officialLeague;
    const league = state.league;
    const competitions = state.competitions;

    const createVariables = {
      name: league.name,
      logo: league.logo ? league.logo.path : "",
      description: league.description,
      website: league.website,
      email: league.email,
      country: league.country,
      sport: league.sport,
      isOfficial: true,
      connected_clubs: league.connected_clubs.map((club) => club.id),
      connected_teams: league.connected_teams.map((team) => team.id),
      competitions: generateLeagueCompetitions(competitions),
    };

    try {
      const createResponse = await axiosGraphQL({
        query: CREATE_OFFICIAL_LEAGUE_SA,
        variables: createVariables,
      });

      if (!createResponse.data.data.errors && league.logo && league.logo.name) {
        const uploadVariables = {
          uploadType: FILE_TYPES.leagueImage,
          itemId: createResponse.data.data.createOfficialLeague.league._id,
          fileName: league.logo.name,
        };

        const uploadResponse = await axiosGraphQL({
          query: UPLOAD_LEAGUE_IMAGE,
          variables: uploadVariables,
        });

        if (!uploadResponse.data.data.errors) {
          const reader = new FileReader();
          reader.onload = function () {
            const arrayBuffer = this.result;
            const array = new Uint8Array(arrayBuffer);
            const blob = new Blob([array]);

            fetch(uploadResponse.data.data.uploadImage, {
              method: "PUT",
              body: blob,
            })
          };
          reader.readAsArrayBuffer(league.logo);

          return createResponse;
        } else {
          return uploadResponse;
        }
      } else {
        return createResponse;
      }
    } catch (error) {
      throw error;
    }
  }
);

//AsyncThunk: getOfficialLeagueCompetitions
export const getOfficialLeagueCompetitions = createAsyncThunk(
  "officialLeague/GetOfficialLeagueCompetitions",
  async (arg, { getState }) => {
    const league = arg.league;
    try {
      const competitionsResponse = await axiosGraphQL({
        query: GET_OFFICIAL_LEAGUE_COMPETITIONS_SA,
        variables: { leagueId: league?._id },
      });
      const competitions =
        competitionsResponse.data.data.GetOfficialLeagueCompetitions;
      const competitionsWithFixtures = await Promise.all(
        competitions?.map((competition) => {
          return axiosGraphQL({
            query: GET_OFFICIAL_LEAGUE_FIXTURES_SA,
            variables: {
              leagueId: league?._id,
              competitionId: competition?._id,
            },
          }).then(async (fixtureResponse) => {
            const fixtures =
              fixtureResponse.data?.data?.getOfficialLeagueFixtures;
            const fixturesWithMatches = await Promise.all(
              fixtures?.map((fixture) => {
                return axiosGraphQL({
                  query: GET_OFFICIAL_LEAGUE_MATCHES_SA,
                  variables: {
                    leagueId: league?._id,
                    competitionId: competition?._id,
                    fixtureId: fixture?._id,
                  },
                }).then((matchResponse) => {
                  const matches =
                    matchResponse.data?.data?.getOfficialLeagueMatches;

                  return {
                    _id: fixture?._id,
                    fixtureName: fixture?.fixtureName,
                    matches: matches.map((match) => ({
                      _id: match?._id,
                      ...match,
                    })),
                  };
                });
              })
            );

            return { ...competition, fixtures: fixturesWithMatches };
          });
        })
      );

      return { league: league, competitions: competitionsWithFixtures };
    } catch (error) {
      throw error;
    }
  }
);

// AsyncThunk: updateOfficialLeagueDetails
export const updateOfficialLeagueDetails = createAsyncThunk(
  "officialLeague/updateOfficialLeagueDetails",
  async (arg, { getState }) => {
    const state = getState().officialLeague;
    const league = state.league;

    const editVariables = {
      leagueId: league?._id,
      logo: league.logo ? league.logo.path : "",
      name: league.name,
      description: league.description,
      website: league.website,
      email: league.email,
      country: league.country,
      isOfficial: true,
      sport: league.sport,
      connected_clubs: league.connected_clubs,
      connected_teams: league.connected_teams,
    };

    try {
      const editResponse = await axiosGraphQL({
        query: EDIT_OFFICIAL_LEAGUE_SA,
        variables: editVariables,
      });

      if (!editResponse.data.data.errors && league.logo && league.logo.name) {
        const uploadVariables = {
          uploadType: FILE_TYPES.leagueImage,
          itemId: league._id,
          fileName: league.logo.name,
        };

        const uploadResponse = await axiosGraphQL({
          query: UPLOAD_LEAGUE_IMAGE,
          variables: uploadVariables,
        });

        if (!uploadResponse.data.data.errors) {
          const reader = new FileReader();
          reader.onload = function () {
            const arrayBuffer = this.result;
            const array = new Uint8Array(arrayBuffer);
            const blob = new Blob([array]);

            fetch(uploadResponse.data.data.uploadImage, {
              method: "PUT",
              body: blob,
            });
          };
          reader.readAsArrayBuffer(league.logo);

          return editResponse;
        } else {
          return uploadResponse;
        }
      } else {
        return editResponse;
      }
    } catch (error) {
      throw error;
    }
  }
);

// AsyncThunk: updateCompetitionDetails
export const updateCompetitionDetails = createAsyncThunk(
  "officialLeague/updateCompetitionDetails",
  async (arg, { getState }) => {
    const state = getState().officialLeague;
    const competition = state.competitions.find(
      (competition) => competition._id === arg.competitionId
    );
    const variables = {
      _id: competition._id,
      name: competition.name,
      type: competition.type,
      format: competition.format,
      ageGroup: competition.ageGroup,
      gender: competition.gender,
      startYear: competition.startYear,
      location: {
        type: "Point",
        address: competition.location?.address,
        coordinates: [
          competition?.location?.coordinates?.[0] || competition.location?.lng,
          competition?.location?.coordinates?.[1] || competition.location?.lat,
        ],
      },
      connected_clubs: competition.clubsInCompetition,
      connected_teams: competition.teamsInCompetition,
    };

    try {
      return axiosGraphQL({
        query: EDIT_OFFICIAL_LEAGUE_COMPETITION_SA,
        variables,
      });
    } catch (error) {
      throw error;
    }
  }
);

// AsyncThunk: updateFixtureDetails
export const updateFixtureDetails = createAsyncThunk(
  "officialLeague/updateFixtureDetails",
  async (arg, { getState }) => {
    const fixtureId = arg.fixtureId;
    const fixtureName = arg.fixtureName;

    try {
      return axiosGraphQL({
        query: EDIT_OFFICIAL_LEAGUE_FIXTURE_SA,
        variables: { fixtureId, fixtureName },
      });
    } catch (error) {
      throw error;
    }
  }
);

// AsyncThunk: updateMatchDetails
export const updateMatchDetails = createAsyncThunk(
  "officialLeague/updateMatchDetails",
  async (arg, { getState }) => {
    const match = arg.match;

    const variables = {
      matchId: match?._id,
      ...match,
      time: match?.time
        ? typeof match?.time === "string"
          ? match?.time
          : match?.time.toFormat("HH:mm")
        : "TBD",
      status: match.status,
      score: match.score,
      location: {
        type: "Point",
        address: match.location?.address,
        coordinates: [
          match?.location?.coordinates?.[0] || match.location?.lng,
          match?.location?.coordinates?.[1] || match.location?.lat,
        ],
      },
    };

    try {
      return axiosGraphQL({
        query: EDIT_OFFICIAL_LEAGUE_FIXTURE_MATCH_SA,
        variables: variables,
      });
    } catch (error) {
      throw error;
    }
  }
);

export const getClubs = createAsyncThunk(
  "officialLeague/getClubs",
  async (arg, { getState }) => {
    const variables = {
      term: "",
      skip: 0,
      limit: 9999,
    };

    try {
      const response = await axiosGraphQL({
        query: GET_CLUBS_SA,
        variables: variables,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const officialLeagueSlice = createSlice({
  name: "officialLeague",
  initialState,
  reducers: {
    addCompetition: (state) => {
      const competition = {
        _id: uuidv4(),
        name: "",
        format: "11x11",
        type: "league",
        ageGroup: "",
        gender: "",
        startYear: `${new Date().getFullYear()}`.substring(2),
        location: {},
        clubsInCompetition: [],
        teamsInCompetition: [],
        fixtures: [
          {
            _id: uuidv4(),
            fixtureName: "",
            leagueId: "",
            competitionId: "",
            matches: [
              {
                _id: uuidv4(),
                homeTeam: "",
                awayTeam: "",
                date: null,
                time: null,
                timezone: "Europe/Athens",
                status: "pending",
                score: "",
                venue: "",
                location: {},
                competitionId: "",
                fixtureId: "",
                leagueId: "",
                errors: {},
              },
            ],
            errors: {},
          },
        ],
        errors: {},
      };

      state.competitions = [...state.competitions, competition];
    },
    addFixture: (state, action) => {
      const { competitionId } = action.payload;
      const fixture = {
        _id: uuidv4(),
        fixtureName: "",
        leagueId: "",
        competitionId: "",
        matches: [
          {
            _id: uuidv4(),
            homeTeam: "",
            awayTeam: "",
            date: null,
            time: null,
            timezone: "Europe/Athens",
            status: "pending",
            score: "",
            venue: "",
            location: {},
            competitionId: "",
            fixtureId: "",
            leagueId: "",
            errors: {},
          },
        ],
        errors: {},
      };

      state.competitions = state.competitions.map((competition) => {
        if (competition._id === competitionId) {
          competition.fixtures = [...competition.fixtures, fixture];
        }
        return competition;
      });
    },
    removeFixture: (state, action) => {
      const { fixtureId, competitionId } = action.payload;

      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.filter(
            (fixture) => fixture?._id !== fixtureId
          );
        }

        return competition;
      });
    },
    removeCompetition: (state, action) => {
      const { competitionId } = action.payload;

      state.competitions = state.competitions.filter(
        (competition) => competition?._id !== competitionId
      );
    },
    copyFixture: (state, action) => {
      const { fixtureId, competitionId } = action.payload;
      const competition = state.competitions.find(
        (competition) => competition._id === competitionId
      );

      if (competition) {
        const fixtureIndex = competition.fixtures.findIndex(
          (fixture) => fixture._id === fixtureId
        );
        if (fixtureIndex !== -1) {
          const copiedFixture = {
            ...competition.fixtures[fixtureIndex],
            _id: uuidv4(),
            matches: competition.fixtures[fixtureIndex].matches.map(
              (match) => ({
                ...match,
                _id: uuidv4(),
              })
            ),
          };

          state.competitions = state.competitions.map((competition) => {
            if (competition._id === competitionId) {
              competition.fixtures = update([...competition.fixtures], {
                $splice: [[fixtureIndex + 1, 0, copiedFixture]],
              });
            }
            return competition;
          });
        }
      }
    },
    copyCompetition: (state, action) => {
      const { competitionId } = action.payload;
      const findData = state.competitions.find(
        (data) => data._id === competitionId
      );

      if (findData) {
        const _copiedField = { ...findData };
        _copiedField._id = uuidv4();

        _copiedField.fixtures = _copiedField.fixtures.map((fixture) => {
          const newFixtureId = uuidv4();
          const newMatches = fixture.matches.map((match) => ({
            ...match,
            _id: uuidv4(),
          }));
          return {
            ...fixture,
            _id: newFixtureId,
            matches: newMatches,
          };
        });

        state.competitions = update([...state.competitions], {
          $splice: [
            [state.competitions.length + 1, 1],
            [
              state.competitions.findIndex((c) => c._id === competitionId) + 1,
              0,
              _copiedField,
            ],
          ],
        });
      }
    },
    moveCompetition: (state, action) => {
      const card = state.competitions.filter(
        (c) => `${c._id}` === action.payload.id
      )[0];
      const index = state.competitions.indexOf(card);

      state.competitions = update(state.competitions, {
        $splice: [
          [index, 1],
          [action.payload.atIndex, 0, card],
        ],
      });
    },
    addMatch: (state, action) => {
      const { id, numberOfMatches, competitionId } = action.payload;
      const matchesToAdd = [];

      for (let i = 0; i < numberOfMatches; i++) {
        matchesToAdd.push({
          _id: uuidv4(),
          homeTeam: "",
          awayTeam: "",
          date: null,
          time: null,
          timezone: "Europe/Athens",
          status: "pending",
          score: "",
          venue: "",
          location: {},
          competitionId: "",
          fixtureId: "",
          leagueId: "",
          errors: {},
        });
      }

      state.competitions = state.competitions.map((competition) => {
        if (competition._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (`${fixture._id}` === id) {
              fixture.matches = [...fixture.matches, ...matchesToAdd];
            }

            return fixture;
          });
        }
        return competition;
      });
    },
    removeMatch: (state, action) => {
      const { fixtureId, competitionId, matchId } = action.payload;

      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (fixture?._id === fixtureId) {
              fixture.matches = fixture.matches.filter(
                (match) => match?._id !== matchId
              );
            }
            return fixture;
          });
        }

        return competition;
      });
    },
    changeFixtureName: (state, action) => {
      const { name, value, id, competitionId } = action.payload;
      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (fixture._id === id) {
              fixture[name] = value;
            }

            return fixture;
          });
        }

        return competition;
      });
    },
    changeCompetitionDetails: (state, action) => {
      const { name, value, competitionId } = action.payload;

      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          if (Array.isArray(competition[name])) {
            if (!competition[name].includes(value)) {
              competition[name].push(value);
            }
          } else {
            competition[name] = value;
          }
        }

        return competition;
      });
    },
    changeMatchDetails: (state, action) => {
      const { name, value, fixtureId, matchId, competitionId } = action.payload;

      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (fixture._id === fixtureId) {
              fixture.matches = fixture.matches.map((match) => {
                if (match?._id === matchId) {
                  match[name] = value;
                }

                return match;
              });
            }

            return fixture;
          });
        }

        return competition;
      });
    },
    changeLeagueDetails: (state, action) => {
      state.league[action.payload.name] = action.payload.value;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLeagueErrors: (state, action) => {
      state.league.errors = action.payload;
    },
    setCompetitionErrors: (state, action) => {
      const { competitionId, errors } = action.payload;
      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.errors = errors || {};
        }
        return competition;
      });
    },
    setFixtureNameError: (state, action) => {
      const { fixtureId, competitionId, error } = action.payload;
      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (fixture?._id === fixtureId) {
              fixture.errors = error || {};
            }
            return fixture;
          });
        }

        return competition;
      });
    },
    setMatchErrors: (state, action) => {
      const { fixtureId, competitionId, matchId, errors } = action.payload;
      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (fixture?._id === fixtureId) {
              fixture.matches = fixture.matches.map((match) => {
                if (match?._id === matchId) {
                  match.errors = errors || {};
                }
                return match;
              });
            }

            return fixture;
          });
        }

        return competition;
      });
    },
    resetData: (state) => {
      state.league = initialState.league;
      state.competitions = initialState.competitions;
      localStorage.removeItem("league_clubs");
    },
    triggerFormsValidation: (state, action) => {
      state.formsValidationTriggered = action.payload;
    },
    triggerRenderMatches: (state, action) => {
      state.renderMatchesTrigger = action.payload;
    },
    updateLeagueCompetitionIdOfFixture: (state, action) => {
      const { fixtureId, competitionId } = action.payload;

      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (fixture._id === fixtureId) {
              fixture.competitionId = competitionId;
              fixture.leagueId = state.league._id;
            }

            return fixture;
          });
        }

        return competition;
      });
    },
    updateLeagueCompetitionFixtureIdOfMatch: (state, action) => {
      const { matchId, fixtureId, competitionId } = action.payload;

      state.competitions = state.competitions.map((competition) => {
        if (competition?._id === competitionId) {
          competition.fixtures = competition.fixtures.map((fixture) => {
            if (fixture._id === fixtureId) {
              fixture.matches = fixture.matches.map((match) => {
                if (match?._id === matchId) {
                  match.fixtureId = fixtureId;
                  match.competitionId = competitionId;
                  match.leagueId = state.league._id;
                }

                return match;
              });
            }

            return fixture;
          });
        }

        return competition;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOfficialLeagueCompetitions.fulfilled,
      (state, action) => {
        state.competitions = action.payload.competitions;
        state.league = action.payload.league;
        state.loading = false;
      }
    );
    builder.addCase(getOfficialLeagueCompetitions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateOfficialLeagueDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateOfficialLeagueDetails.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateCompetitionDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateCompetitionDetails.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateFixtureDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateFixtureDetails.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateMatchDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateMatchDetails.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getClubs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getClubs.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createOfficialLeague.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createOfficialLeague.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createOfficialLeague.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  addCompetition,
  addFixture,
  removeFixture,
  removeCompetition,
  addMatch,
  removeMatch,
  copyFixture,
  copyCompetition,
  changeFixtureName,
  changeCompetitionDetails,
  changeMatchDetails,
  changeLeagueDetails,
  moveCompetition,
  resetData,
  setLoading,
  setLeagueErrors,
  setCompetitionErrors,
  setFixtureNameError,
  setMatchErrors,
  triggerFormsValidation,
  triggerRenderMatches,
  updateLeagueCompetitionIdOfFixture,
  updateLeagueCompetitionFixtureIdOfMatch,
} = officialLeagueSlice.actions;

export const validateForms = (value) => (dispatch) => {
  dispatch(triggerFormsValidation(value));

  return Promise.resolve();
};

export const renderMatches = (value) => (dispatch, _getState) => {
  dispatch(triggerRenderMatches(value));

  return Promise.resolve();
};

export const getCompetitions = () => (_dispatch, getState) => {
  return JSON.parse(JSON.stringify(getState().officialLeague.competitions));
};

export default officialLeagueSlice.reducer;
