import React, {lazy} from 'react';
import PropTypes from 'prop-types';
import {Route, Routes} from "react-router-dom";
import Box from "@mui/material/Box";

const OfficialLeagueList = lazy(() => import("./OfficialLeagueList"));
const OfficialLeagueCreation = lazy(() => import("./OfficialLeagueCreation"));
const OfficialLeagueEdit = lazy(() => import("./OfficialLeagueEdit"));


const OfficialLeagues = props => {
    return (
        <Box component="main" sx={{flexGrow: 1, p: 3}}>
            <Routes>
                <Route path="/create" element={<OfficialLeagueCreation />} />
                <Route path="/:id" element={<OfficialLeagueEdit />} />
                <Route path="/" element={<OfficialLeagueList />} />
            </Routes>
        </Box>
    );
};

OfficialLeagues.propTypes = {

};

export default OfficialLeagues;
