import React, { useState, useContext } from "react";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { SnackBarContext } from "../../context/SnackBarContext";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";

const Autocomplete = ({
  query,
  queryVariables,
  showSearchListResults,
  mapResults,
  label,
  styles,
  onChange,
  onChangeName,
  placeholder
}) => {
  const [searchState, setSearchState] = useState({
    searchTerm: "",
    selectedItem: null,
    results: [],
  });

  const {axiosGraphQL} = useGraphql()
  const {errorLog} = useLog()
  const snackBar = useContext(SnackBarContext);
  
  const handleOnMenuItemClick = (item) => {
    setSearchState({ ...searchState, selectedItem: item.text, results: []});
    onChange({target: {name: onChangeName, value: item.value}})
  }

  const updateField = ({ value }) => {
    if (searchState.selectedItem || !value || value === '' || value?.length < 3) {
      setSearchState({ ...searchState, searchTerm: value, selectedItem: null, results: [] })
      return
    }

    axiosGraphQL({ query, variables: { term: value, ...queryVariables } })
      .then((response) => {
        setSearchState({ ...searchState, searchTerm: value, results: response.data.data, selectedItem: null });
      }).catch((error) => {
        errorLog(error, 'Autocomplete', 'updateField', Object.keys({query})[0]);
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
            });
        snackBar.setSnackbarOpen(true);
      });
  };

  return (
    <Stack direction={'column'} spacing={0.5}>
      <span className={styles.label}>{label}</span>
        <TextField
          size="small"
          fullWidth
          placeholder={placeholder}
          name="searchTerm"
          value={searchState.selectedItem ?? searchState.searchTerm}
          onChange={({ target }) => updateField(target)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {showSearchListResults && mapResults(searchState.results)?.map((item) => (
              <MenuItem key={item.value} value={item.value} onClick={() => handleOnMenuItemClick(item)}>
                  {item.text}
              </MenuItem>
        ))}
    </Stack>
  );
};

export default Autocomplete;
