import {makeStyles} from "@mui/styles";
import {fonts} from "../../theme/fonts";
import {colors} from "../../theme/colors";
const {text} = fonts
const {Grey, Base} = colors

export default makeStyles({
    title: {
        ...text.lg.medium,
        color: Grey[900]
    },
    xlTitle: {
        ...text.xl.medium,
        color: Grey[900]
    },
    modalBody: {
        ...text.sm.normal,
        color: Grey[500],
        marginBottom: '32px',
    },
    modalCloseButton: {
        marginLeft: 'calc(40% - 32px)',
        borderRadius: '8px',
        backgroundColor: Grey[100],
        '&:hover': {
            backgroundColor: Grey[200],
        }
    },
    modalActions: {
        padding: '0 32px'
    },
    modalHeader: {
        padding: '16px 16px 0 4px'
    },
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        backgroundColor: Base.White,
        boxShadow: 24,
        borderRadius: '12px',
    },
    contentContainer: {
        padding: '4px 24px 24px'

    }
})
