import firebase from "./firebase";
import "firebase/compat/auth";

export const getStorage = () => {
  return localStorage.getItem("rememberMe") ? localStorage : sessionStorage;
};

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  // return (
  //   localStorage.getItem("userData") &&
  //   localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  // );
  return getStorage().getItem("user") && getStorage().getItem("token");
};

export const hasUserVisibility = (routeRoles) => {
  if (!routeRoles) {
    return true;
  }

  if (!isUserLoggedIn()) {
    return false;
  }

  const user = JSON.parse(getStorage().getItem("user"));
  const userRoles = user?.roles;

  if (!userRoles.length) {
    return false;
  }

  return Boolean(
    userRoles.find((userRole) => {
      return Boolean(routeRoles.find((role) => role === userRole));
    })
  );
};
// export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const getUserData = () =>
  getStorage().getItem("user")
    ? JSON.parse(getStorage().getItem("user"))
    : null;

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return { name: "access-control" };
  return { name: "auth-login" };
};

function setUpRecaptcha() {
  return new firebase.auth.RecaptchaVerifier("recaptcha-container", {
    size: "invisible",
    callback: () => {
      console.log("captcha resolved");
    },
    "expired-callback": () => {
      console.log("captcha expired");
    },
  });
}

export const signIn = (mobile) => {
  const appVerifier = setUpRecaptcha();
  return firebase
    .auth()
    .signInWithPhoneNumber(mobile, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      return confirmationResult;
    })
    .catch((error) => {
      console.error("SMS not sent, => ", error);
	  throw error;
      // Error; SMS not sent
      // ...
    });
};

export function verificationCode(confirmationResult, code) {
  return confirmationResult
    .confirm(code)
    .then((result) => {
      // User signed in successfully.
      const user = result.user;
      getStorage().setItem("refreshToken", user?.refreshToken);
      return user?.getIdToken(true);
    })
    .catch((error) => {
      console.error("User couldn't sign in (bad verification code?) => ", error);
	  throw error;
      // User couldn't sign in (bad verification code?)
      // ...
    });
}

export function getFirebaseUser() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log("[utils.js - getFirebaseUser] user => ", user);
        resolve(user);
      } else {
        reject();
      }
    });
  });
}
