export const fonts = {
    text: {
        xs: {
            normal: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '18px'
            },
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '18px'
            }
        },
        s: {
            regular: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px'
            }
        },
        sm: {
            normal: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px'
            },
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '20px'
            },
            regular: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px'
            },
            bold: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '20px'
            }
        },
        m: {
            regular: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px'
            }
        },
        md: {
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '24px'
            },
            normal: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px'
            },
            regular: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px'
            },
            semibold: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '36px',
                lineHeight: '44px'
            }
        },
        lg: {
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px'
            }
        },
        xl: {
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '30px'
            },
            normal: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '30px'
            },
            bold: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '30px'
            }
        }
    },
    display: {
        xs: {
            normal: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '18px'
            },
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '30px',
                lineHeight: '38px'
            }
        },
        sm: {
            normal: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px'
            },
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '30px',
                lineHeight: '38px'
            }
        },
        md: {
            medium: {
                fontFamily: 'Play',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '24px'
            }
        },
    }

}
