import React from "react";
import Table from "../../../Components/Table/Table";
import UserInvitationsTableRow from "./UserInvitationsTableRow";

const schema = [
  {
    id: 'from',
    label: 'From'
  },
  {
    id: 'to',
    label: 'To'
  },
  {
    id: 'referenceTo',
    label: 'Reference To'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'type',
    label: 'Type'
  },
]

const UserInvitationsTable = ({ data, menuActions = [], lastElementRef }) => {
  const rowElement = (dato, index) => <UserInvitationsTableRow
      key={dato._id}
      lastElementRef={lastElementRef}
      data={dato}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={data} columns={schema} rowElement={rowElement}/>);
};

export default UserInvitationsTable;
