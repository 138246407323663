import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import LoadingButton from "@mui/lab/LoadingButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


import logo from "../../assets/images/admin-icon-2/Logo.png";
import playerdex from "../../assets/images/admin-icon-2/playerdex.png";
import manager from "../../assets/images/admin-icon-2/manager.png";
import { getStorage, signIn, verificationCode } from "../../auth/utils";
import { GET_USER } from "../../graphql/userGraphql";
import { AuthContext } from "../../context/AuthContext";
import backgroundImage from "../../assets/images/admin-icon-2/bg-black.png";
import { SnackBarContext } from "../../context/SnackBarContext";
import { useTranslation } from "../../context/Internationalization";
import TranslationToggle from "../../Components/TranslationToggle";
import Stack from "@mui/material/Stack";
import useFormStyles from '../../Components/Form/formStyles'
import CheckedIcon from '../../assets/images/icons/_Checkbox base.svg'
import UnCheckedIcon from '../../assets/images/icons/_UnCheckbox base.svg'
import { styled } from '@mui/material/styles';

import {makeStyles} from "@mui/styles";
import {colors} from "../../theme/colors";
import {fonts} from "../../theme/fonts";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";

const {Grey} = colors;
const {text} = fonts;

const useStyles = makeStyles({
  descriptionText: {
    ...text.m.regular,
    color: Grey[500],
    textAlign: 'center',
    marginBottom: '24px'
  },
  buttonText: {
    ...text.md.normal,
    color: Grey[800]
  }
})

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 20,
  height: 20,
  boxShadow:
      theme.palette.mode === 'dark'
          ? '0 0 0 1px rgb(16 22 26 / 40%)'
          : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
      `url("${UnCheckedIcon}")`,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
        `url("${CheckedIcon}")`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const initialState = {
  mobile: "",
  code: "",
  confirmationResult: false,
  rememberMe: localStorage.getItem("rememberMe"),
  status: "idle",
};

export const Login = () => {
  const [form, setForm] = useState(initialState);
  const {t} = useTranslation()
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const snackBar = useContext(SnackBarContext);
  const styles = useStyles()
  const formStyles = useFormStyles()
  const {axiosGraphQL} = useGraphql()
  const {errorLog} = useLog()

  useEffect(() => {
    if (getStorage().getItem("user") && getStorage().getItem("token")) {
      const intervalId = setInterval(() => {
        if (getStorage().getItem("user")) {
          clearInterval(intervalId);
          navigate("/news");
        }
      }, 100);
    }
  }, [navigate]);

  const updateField = ({ name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleOnChange = (value) => {
    setForm({
      ...form,
      mobile: value?.replace(/ /g, ""),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!form.confirmationResult) {
      setForm({ ...form, status: "pending" });
      signIn(`${form.mobile?.trim()}`).then((result) => {
      setForm({
        ...form,
        confirmationResult: result,
        status: "resolve",
        code: "",
      })
	  }).catch((error) => {
		errorLog(error, "Login", "handleSubmit", "signIn");
		snackBar.setOptions({
		  alertMessage: t('error_during_login'),
		  alertSeverity: "error",
		});
		snackBar.setSnackbarOpen(true);
		setTimeout(() => {
		  window.location.reload()
		}, 2000)
		setForm({ ...initialState });
	  });
    } else {
      verificationCode(form.confirmationResult, form.code).then((token) => {
        getStorage().setItem("token", token);
        getStorage().setItem("tokenLastValidity", Date.now().toString());
        setForm({
          ...form,
          status: "pending",
        });

        axiosGraphQL({ query: GET_USER }).then((response) => {
          if (response.data.data.me.club && response.data.data.me.user) {
            const user = response.data.data.me.user;
            user.club = response.data.data.me.club;
            auth.setAuthState({ user, token });
            getStorage().setItem("user", JSON.stringify(user));
            setForm({
              ...form,
              status: "resolve",
            });
            navigate("/news", { replace: true });

          } else {
            snackBar.setOptions({
              alertMessage:  t('error_during_login'),
              alertSeverity: "error",
            });
            snackBar.setSnackbarOpen(true);
            setTimeout(() => {
              window.location.reload()
            }, 2000)
            setForm({ ...initialState });
          }
        }).catch((error) => {
          errorLog(error, "Login", "handleSubmit", "GET_USER");
          snackBar.setOptions({
            alertMessage: t('error_during_login'),
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
          setTimeout(() => {
            window.location.reload()
          }, 2000)
          setForm({ ...initialState });
        });
      }).catch((error) => {
		errorLog(error, "Login", "handleSubmit", "verificationCode");
		snackBar.setOptions({
		  alertMessage: t('error_during_login'),
		  alertSeverity: "error",
		});
		snackBar.setSnackbarOpen(true);
		setTimeout(() => {
		  window.location.reload()
		}, 2000)
		setForm({ ...initialState });
	  });
    }
  };

  const handleRememberMe = ({ target: { checked } }) => {
    if (checked) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      localStorage.setItem("rememberMe", 1);
      setForm({ ...form, rememberMe: 1 });
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("rememberMe");
      setForm({ ...form, rememberMe: 0 });
    }
  };

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#000",
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box component="div" sx={{ width: "400px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: '24px'
          }}
        >
          <Box>
            <img style={{ width: "80px" }} src={logo} alt="PlayerDex logo" />
          </Box>
          <Box>
            <img style={{ width: "80px" }} src={playerdex} alt="playerdex" />
          </Box>
          <Box>
            <img style={{ width: "80px" }} src={manager} alt="manager" />
          </Box>
        </Box>
        <div className={styles.descriptionText}>
          {t('enter_phone_msg')}
        </div>
        <form
          className="login-form"
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div id="recaptcha-container"></div>
          {!form.confirmationResult && <FormGroup>
            <MuiPhoneNumber
                defaultCountry={"gr"}
                onlyCountries={["gr", "cy"]}
                value={form.mobile}
                disabled={form.confirmationResult}
                fullWidth
                onChange={handleOnChange}
                size={"small"}
                variant={'outlined'}
                InputLabelProps={{
                  style: { color: "#7E8CA7", fontFamily: "Play" },
                }}
                InputProps={{
                  disableUnderline: true,
                  variant: 'outlined',
                  classes:{ notchedOutline: formStyles.input},
                  style: {
                    backgroundColor: "#182229",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    border: "1px solid #212D38",
                    marginBottom: '16px',
                    padding: '6px 0 4px 0'
                  },
                }}
            />
          </FormGroup>}

          {form.confirmationResult && <FormGroup>
            <TextField
                fullWidth
                name="code"
                required
                value={form.code}
                size={'small'}
                placeholder={t('insert_code')}
                onChange={({ target }) => updateField(target)}
                InputProps={{
                  disableUnderline: true,
                  classes:{ notchedOutline: formStyles.input},
                  style: {
                    backgroundColor: "#182229",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    border: "1px solid #212D38",
                    marginBottom: '16px',
                    padding: '6px 0 4px 0'
                  },
                }}
            />
          </FormGroup>
          }
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <FormGroup>
              <FormControlLabel
                  id="rememberMe"
                  sx={{ color: "#fff" }}
                  control={
                    <Checkbox
                        checked={!!form.rememberMe}
                        onChange={handleRememberMe}
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        name="rememberMe"
                    />
                  }
                  label={t('remember_me')}
              />

            </FormGroup>
            <TranslationToggle/>

          </Stack>

          <LoadingButton
            sx={{
              marginTop: "20px",
            }}
            type="submit"
            loading={form.status === "pending"}
            loadingPosition="start"
            variant="contained"
          >
            <span className={styles.buttonText}>{form.confirmationResult ? t("sign_in")  : t('send_code')}</span>
          </LoadingButton>
        </form>

      </Box>
    </Box>
  );
};
export default Login;
