import React from 'react';
import {colors} from "../../theme/colors";
const {Grey} = colors
const  Icon = ({src, alt, contained, size = 16}) => {
    if(contained) {
        return (<div style={styles.container}>
            <img style={{width: size, height: size}} src={src} alt={alt}/>
        </div>);

    }
    return (<img style={{width: size, height: size}} src={src} alt={alt}/>);
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        border: `2px solid ${Grey[50]}`,
        padding: 8
    }
}
Icon.propTypes = {

};

export default Icon;
