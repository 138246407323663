import React, { useState } from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@mui/material";
import TableActionsMenu from "../../../Components/TableActionsMenu/TableActionsMenu";
import Typography from "@mui/material/Typography";
import { useTranslation } from "../../../context/Internationalization";

const ClubsTableRow = ({ club, menuActions, lastElementRef}) => {
  const {t} = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuActionsOpen = Boolean(menuAnchorEl);

  const handleClickActionMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setMenuAnchorEl(null);
  };




  return (
    <TableRow
      ref={lastElementRef}
      key={club._id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
       <Typography variant={'subtitle1'}>{club.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'subtitle1'}>{club.country}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'subtitle1'}>{club.founded || ''}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'subtitle1'}>{club.contactInfo?.websiteUrl}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'subtitle1'}>{club.isPremium ? t('yes'): t('no')}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'subtitle1'}>{club.isOfficial ? t('yes'): t('no')}</Typography>
      </TableCell>
      <TableCell>
        <TableActionsMenu
          handleClickAction={handleClickActionMenu}
          handleCloseActionMenu={handleCloseActionMenu}
          rowData={club}
          actions={menuActions}
          anchorEl={menuAnchorEl}
          open={menuActionsOpen}
        />
      </TableCell>
    </TableRow>
  );
};

ClubsTableRow.propTypes = {};

export default ClubsTableRow;
