import React, {createContext, useContext, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {makeStyles} from "@mui/styles";
import {colors} from "../theme/colors";
import {getStorage} from "../auth/utils";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./AuthContext";
const {Warning, Base} = colors

const SuperAdminContext = createContext();
const { Provider } = SuperAdminContext;

const useStyles = {
    impersonateBanner: {

        backgroundColor: Warning[700],
        color: Base.White,
        zIndex: 1300
    },
    impersonateLeaveButton: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}
const SuperAdminProvider = ({ children }) => {
    const [user, setUser] = useState()
    const {setImpersonate} = useContext(AuthContext);
    const navigate = useNavigate()

    useEffect(() => {
        const impersonate =  getStorage().getItem('impersonate')
        if(impersonate) {
            setUser(JSON.parse(impersonate))
        }
    }, [])
    const handleSetUser = (user) => {
        getStorage().setItem('impersonate', JSON.stringify(user))

        setUser(user)
    }

    const handleLeaveImpersonate = () => {
        setUser()
        getStorage().removeItem('impersonate')
        setImpersonate(null)
        navigate('/super-admin/impersonate')
    }

    return (
        <Provider value={{user, setUser: handleSetUser}}>
            <Stack direction={'column'}>
                {user && <Stack direction={'row'} justifyContent={'space-between'} style={useStyles.impersonateBanner}>
                    {user?.firstName} {user?.lastName}
                    <div style={useStyles.impersonateLeaveButton} onClick={handleLeaveImpersonate}>Leave impersonate</div>
                </Stack>}
                {children}
            </Stack>

        </Provider>
    );
};


export const useSuperAdmin = () => {
    return useContext(SuperAdminContext)
}

export default SuperAdminProvider
