import React from 'react';
import PropTypes from 'prop-types';
import Stack from "@mui/material/Stack";
import useStyles from "./modalStyles";

const ModalTitle = ({title}) => {
    const styles = useStyles()

    return (
        <Stack justifyContent={'center'} alignItems={'center'} >
            <span className={styles.title}>{title}</span>
        </Stack>
    );
};

ModalTitle.propTypes = {

};

export default ModalTitle;
