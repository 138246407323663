import React, { useContext, useEffect, useState, Suspense } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import { NavLink, useLocation } from "react-router-dom";

import { useAuthContext } from "./context/AuthContext";
import playerDexLogo from "./assets/images/admin-icon-2/Content.png";
import playerDex from "./assets/images/admin-icon-2/playerdex.png";
import HomeIcon from "./assets/images/icons/home.png";
import HomeSelectedIcon from "./assets/images/icons/home_selected.png";
import ScheduleIcon from "./assets/images/icons/schedule.png";
import ScheduleSelectedIcon from "./assets/images/icons/schedule_selected.png";
import ClubIcon from "./assets/images/icons/club.png";
import ClubSelectedIcon from "./assets/images/icons/club_selected.png";
import MessagesIcon from "./assets/images/icons/messages.png";
import MessagesSelectedIcon from "./assets/images/icons/messages_selected.png";
import ShopIcon from "./assets/images/icons/shopIcon.svg";
import ShopSelectedIcon from "./assets/images/icons/shopSelectedIcon.svg";
import notificationIcon from "./assets/images/icons/notificationIcon.svg";
import notificationSelectedIcon from "./assets/images/icons/notificationSelectedIcon.svg";
import formsIcon from "./assets/images/icons/formsIcon.svg";
import formsSelectedIcon from "./assets/images/icons/formsSelectedIcon.svg";

import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { colors } from "./theme/colors";
import { fonts } from "./theme/fonts";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Search } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_PROFILES } from "./pages/Membership/MembershipService";
import { useNavigate } from "react-router";
import { Avatar, Chip, MenuItem } from "@mui/material";
import LogoutIcon from "./assets/images/Svg/LogoutIcon";
import { getProfilesTeamDetails, isSuperAdmin } from "./utility/Utils";
import { useTranslation } from "./context/Internationalization";

import Loader from "./Components/Loader";
import TranslationToggle from "./Components/TranslationToggle";
import { GET_USER } from "./graphql/userGraphql";
import { getStorage } from "./auth/utils";
import { useInvitations } from "./context/InvitationContext";
import { GET_CLUB_TEAMS } from "./pages/Posts/PostsService";
import LogoutConfirmationModal from "./Components/LogoutConfirmationModal";
import { useMessages } from "./context/MessagesContext";
import { useAppState } from "./context/AppContext";
import { SnackBarContext } from "./context/SnackBarContext";
import useGraphql from "./Hooks/useGraphql";
import useLog from "./Hooks/useLog";

const { Grey, Base } = colors;

const {
  text: { md, sm },
} = fonts;

const drawerWidth = 312;

let menuLinks = (t) => [
  {
    label: t("home"),
    path: "/news",
    icon: (isSelected) => (
      <img
        src={isSelected ? HomeSelectedIcon : HomeIcon}
        style={{ width: 24, height: 24 }}
        alt={"home-icon"}
      />
    ),
  },
  {
    label: t("schedule_title"),
    path: "/activities",
    icon: (isSelected) => (
      <img
        src={isSelected ? ScheduleSelectedIcon : ScheduleIcon}
        style={{ width: 24, height: 24 }}
        alt={"schedule-icon"}
      />
    ),
  },
  {
    label: t("club_page_title"),
    path: "/club/teams",
    icon: (isSelected) => (
      <img
        src={isSelected ? ClubSelectedIcon : ClubIcon}
        style={{ width: 24, height: 24 }}
        alt={"club-icon"}
      />
    ),
  },
  {
    label: t("messages"),
    path: "/messages",
    icon: (isSelected) => (
      <img
        src={isSelected ? MessagesSelectedIcon : MessagesIcon}
        style={{ width: 24, height: 24 }}
        alt={"message-icon"}
      />
    ),
  },
  {
    label: t("shop"),
    path: "/shop",
    icon: (isSelected) => (
      <img
        src={isSelected ? ShopSelectedIcon : ShopIcon}
        style={{ width: 24, height: 24 }}
        alt={"message-icon"}
      />
    ),
  },
  {
    label: t("forms"),
    path: "/forms",
    icon: (isSelected) => (
      <img
        src={isSelected ? formsSelectedIcon : formsIcon}
        style={{ width: 24, height: 24 }}
        alt={"message-icon"}
      />
    ),
  },
];

const MenuList = ({ menu, t, pathname, style }) => {
  return (
    <List style={style}>
      {menu(t).map((item, index) => {
        if (item.divider) {
          return (
            <div
              key={"divider" + index}
              style={{ borderTop: "1px solid #1F2831", margin: "16px" }}
            />
          );
        }

        const isActive = pathname.includes(item.path);

        return (
          <ListItem key={item.path} disablePadding>
            <ListItemButton
              style={{ paddingLeft: "24px", justifyContent: "space-between" }}
            >
              <div style={{ alignItems: "center", display: "flex" }}>
                <ListItemIcon style={{ minWidth: "38px" }}>
                  {item.icon(isActive)}
                </ListItemIcon>

                <NavLink
                  style={isActive ? styles.selectedLink : styles.link}
                  to={item.path}
                >
                  {item.label}
                </NavLink>
              </div>
              {item.badge && (
                <Chip
                  style={{ height: 22 }}
                  label={item.badge}
                  color={"primary"}
                />
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

const AppShell = ({ window, children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [players, setPlayers] = useState([]);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const auth = useAuthContext();
  const appState = useAppState();
  const { chatConnect } = useMessages();

  const { pathname } = useLocation();

  const club = auth.authState.user.club;
  const clubId = club?._id;
  const user = auth.authState.user;
  const snackBar = useContext(SnackBarContext);
  const { invitations } = useInvitations();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();

  const impersonateProc = () => {
    const impersonate = getStorage().getItem("impersonate");
    if (impersonate) {
      getStorage().setItem("impersonate", JSON.stringify(user));
    } else {
      getStorage().setItem("user", JSON.stringify(user));
    }
  };

  useEffect(() => {
    if (!clubId) {
      snackBar.setOptions({
        alertMessage: t("your_account_does_not_belong_to_a_club"),
        alertSeverity: "error",
      });
      snackBar.setSnackbarOpen(true);
      setTimeout(() => {
        handleLogout();
      }, 4000);
    } else {
      axiosGraphQL({
        query: GET_CLUB_TEAMS,
        variables: {
          clubId,
          teamsSkip: 0,
          teamsLimit: 500,
        },
      }).then((response) => {
        const clubTeams = response.data.data.ClubTeams;
        appState.setClubTeams(clubTeams);
        Intercom("boot", {
          app_id: "f5ktqeup",
          user_id: user?._id,
          phone: user?.mobile,
          avatar: {
            type: "avatar",
            image_url: user?.photo,
          },
          company_id: clubId,
          company_name: user?.club?.name,
          company: {
            company_id: clubId,
            name: user?.club?.name,
            plan: user?.club?.isPremium ? "Premium" : "Basic",
            monthly_spend: user?.club?.monthlySpend,
            size: clubTeams?.length || 0,
            industry: clubTeams && clubTeams[0] && clubTeams[0].sport,
            company_website: user?.club?.website,
          },

          name: `${user?.firstName} ${user?.lastName}`, // Full name
          created_at: Date.now(), // Signup date as a Unix timestamp
        });

        Intercom("update");
        return response.data.data.ClubTeams;
      }).catch((error) => {
        errorLog(error, "AppShell", "useEffect", "GET_CLUB_TEAMS");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
            });
        snackBar.setSnackbarOpen(true);
      });

      axiosGraphQL({ query: GET_USER }).then((response) => {
        if (
          response.data.data.me.club &&
          response.data.data.me.clubs &&
          response.data.data.me.user
        ) {
          const user = response.data.data.me.user;
          user.clubs = response.data.data.me.clubs;
          if (user.clubs.length > 1) {
            user.club = user.clubs.find((club) => club._id === clubId);
          } else {
            user.club = response.data.data.me.club;
          }

          auth.setAuthState({ user, token: getStorage().getItem("token") });

          chatConnect();

          impersonateProc();
        }
      }).catch((error) => {
        errorLog(error, "AppShell", "useEffect", "GET_USER");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
            });
        snackBar.setSnackbarOpen(true);
      });
    }
  }, []);

  const isUserSuperAdmin = isSuperAdmin(user);

  let secondMenuLinks = (t) => [
    {
      label: t("notifications"),
      path: "/notifications",
      badge: invitations?.length,
      icon: (isSelected) => (
        <img
          src={isSelected ? notificationSelectedIcon : notificationIcon}
          style={{ width: 24, height: 24 }}
          alt={"notifications-icon"}
        />
      ),
    },
  ];

  let superAdminMenuLinks = (t) => {
    return [
      { divider: true },
      {
        label: t("clubs_admin"),
        path: "/super-admin/clubs",
        icon: (isSelected) => (
          <img
            src={isSelected ? ClubSelectedIcon : ClubIcon}
            style={{ width: 24, height: 24 }}
            alt={"club-icon"}
          />
        ),
      },
      {
        label: "Clubs Search user",
        path: "/super-admin/impersonate",
        icon: (isSelected) => (
          <img
            src={isSelected ? ClubSelectedIcon : ClubIcon}
            style={{ width: 24, height: 24 }}
            alt={"club-icon"}
          />
        ),
      },
      {
        label: t("official_leagues"),
        path: "/super-admin/official-leagues",
        icon: (isSelected) => (
          <img
            src={isSelected ? ClubSelectedIcon : ClubIcon}
            style={{ width: 24, height: 24 }}
            alt={"club-icon"}
          />
        ),
      },
    ];
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    if (Intercom) {
      Intercom("shutdown");
    }
    auth.logout();
  };

  useEffect(() => {
    if (search.length >= 3) {
      axiosGraphQL({
        query: GET_PROFILES,
        variables: {
          term: search,
          clubId,
          skip: 0,
          limit: 500,
        },
      }).then(async (response) => {
        const players = response.data.data.ClubProfiles || [];

        let teamDetails = await getProfilesTeamDetails(players, axiosGraphQL, 'AppShell', snackBar, errorLog);
        const playersOptions = [];
        for (const player of players) {
          if (teamDetails[player?.team]) {
            playersOptions.push({
              label: `${player?.firstName} ${player?.lastName} (${teamDetails[player?.team]?.name})`,
              value: player?._id,
              teamId: teamDetails[player?.team]?._id,
            });
          } else {
            playersOptions.push({
              label: `${player?.firstName} ${player?.lastName}`,
              value: player?._id,
            });
          }
        }
        setPlayers(playersOptions);
      }).catch((error) => {
        errorLog(error, "AppShell", "useEffect", "GET_PROFILES");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
            });
        snackBar.setSnackbarOpen(true);
      });
    }
  }, [search]);

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
  };
  const navigate = useNavigate();

  const handleOnClick = (e, value) => {
    if (value?.value) {
      navigate(`/players/${value?.value}`);
    }
  };

  const updateSelectedClub = (selectedClubId) => {
    const selectedClub = user.clubs.find((club) => club._id === selectedClubId);
    user.club = selectedClub;

    auth.setAuthState({
      user: {
        ...user,
        club: selectedClub,
      },
      token: getStorage().getItem("token"),
    });

    chatConnect();

    impersonateProc();
  };

  const drawer = (
    <div>
      <Stack
        sx={{ marginTop: "32px", marginLeft: "24px" }}
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <img
            style={{ width: "32px", height: "32px" }}
            src={playerDexLogo}
            alt="PlayerDex logo"
          />
          <img
            style={{
              width: "117px",
              height: "12px",
              display: "inline-block",
              marginLeft: "8px",
            }}
            src={playerDex}
            alt="PlayerDex"
          />
        </Stack>
        <TranslationToggle />
      </Stack>
      <Divider />
      <Stack
        direction={"row"}
        alignItems={"center"}
        style={styles.searchFieldContainer}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={players}
          fullWidth
          value={""}
          noOptionsText={""}
          onChange={handleOnClick}
          onInputChange={(event, value, reason) => {
            if (reason === "clear") {
              setSearch("");
              setPlayers([]);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleSearch}
              InputProps={{
                ...params.InputProps,
                style: styles.searchField,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: Grey[500] }} />
                  </InputAdornment>
                ),
              }}
              placeholder={t("search")}
              fullWidth
            />
          )}
        />
      </Stack>
      {user?.clubs?.length > 1 ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          style={styles.searchFieldContainer}
        >
          <TextField
            select
            fullWidth
            name={"selectClub"}
            defaultValue={user.club._id}
            InputProps={{
              style: styles.clubsField,
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt={user.club.name}
                    sx={{ width: 24, height: 24 }}
                    src={user.club.logo || user.club.name.substring(0, 1)}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              updateSelectedClub(event.target.value);
            }}
          >
            {user.clubs.map((club) => (
              <MenuItem key={club._id} value={club._id}>
                {club.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      ) : null}
      <MenuList menu={menuLinks} t={t} pathname={pathname} />
      {isUserSuperAdmin && (
        <MenuList menu={superAdminMenuLinks} t={t} pathname={pathname} />
      )}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const onLogout = () => {
    setLogoutModal(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box sx={{ display: "flex", paddingBottom: "56px" }}>
        <CssBaseline />
        {/* <TopBar
          links={links}
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
          handleLogout={handleLogout}
        /> */}
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#0D151A",
              },
            }}
          >
            {drawer}
            <div style={{ height: "100%" }} />
            <LogoutConfirmationModal
              logout={handleLogout}
              open={logoutModal}
              onClose={() => setLogoutModal(false)}
            />

            <div style={{ padding: "0 16px" }}>
              <List style={{ borderTop: "1px solid #1F2831" }}>
                <ListItem key={"logout"} disablePadding onClick={handleLogout}>
                  <ListItemButton>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      style={{ width: "100%" }}
                      justifyContent={"space-between"}
                      spacing={1}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <ListItemIcon style={{ minWidth: "38px" }}>
                          <Avatar fontSize="small" src={user?.photo} />
                        </ListItemIcon>
                        <Stack direction={"column"} spacing={0}>
                          <span style={styles.userName}>
                            {user?.firstName} {user?.lastName}
                          </span>
                          <span style={styles.userTitle}>{"Club Manager"}</span>
                        </Stack>
                      </Stack>

                      <LogoutIcon />
                    </Stack>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#0D151A",
              },
            }}
            open
          >
            {drawer}
            <div style={{ height: "100%" }} />
            <MenuList menu={secondMenuLinks} t={t} pathname={pathname} />

            <div style={{ padding: "0 16px" }}>
              <List style={{ borderTop: "1px solid #1F2831" }}>
                <ListItem key={"logout"} disablePadding onClick={onLogout}>
                  <ListItemButton>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      style={{ width: "100%" }}
                      justifyContent={"space-between"}
                      spacing={1}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <ListItemIcon style={{ minWidth: "38px" }}>
                          <Avatar fontSize="small" src={user?.photo} />
                        </ListItemIcon>
                        <Stack direction={"column"} spacing={0}>
                          <span style={styles.userName}>
                            {user?.firstName} {user?.lastName}
                          </span>
                          <span style={styles.userTitle}>{"Club Manager"}</span>
                        </Stack>
                      </Stack>

                      <LogoutIcon />
                    </Stack>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </Drawer>
        </Box>

        {/* <Toolbar /> */}
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </Box>
    </LocalizationProvider>
  );
};

const styles = {
  link: {
    color: Grey[500],
    ...md.medium,
    textDecoration: "none",
  },
  selectedLink: {
    color: Base.PlayerDex,
    ...md.medium,
    textDecoration: "none",
  },
  searchFieldContainer: {
    padding: "0 24px",
    width: "100%",
  },
  searchFieldIcon: {
    borderBottomLeftRadius: "8px",
    borderTopLeftRadius: "8px",
    backgroundColor: Grey[800],
    height: "40px",
  },
  searchField: {
    backgroundColor: Grey[800],
    color: Grey[500],
    borderRadius: "8px",
    marginTop: "24px",
    marginBottom: "24px",
    border: "none",
    height: "40px",
    paddingTop: "4px",
    ...md.normal,
  },
  clubsField: {
    backgroundColor: Grey[800],
    color: Grey[500],
    borderRadius: "8px",
    marginTop: 0,
    marginBottom: "24px",
    border: "none",
    height: "40px",
    paddingTop: "4px",
    ...md.normal,
  },
  userName: {
    ...sm.bold,
    color: Base.White,
  },
  userTitle: {
    ...sm.normal,
    color: Grey[500],
  },
};

export default AppShell;
