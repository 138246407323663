export const EVENT_FIELDS = `
  fragment eventFields on Event {
    _id
    club
    team
    opponentTeam
    opponentClub
    leagueFixture
    fromTime
    toTime
    homeMatch
    meetTime
    location {
      address
      coordinates
      type
    }
    eventType
    title
    isCancelled
    isUpdated
    groupId
    metadata {
      trainingType
    }
  }
`;

export const GET_EVENTS = `
  ${EVENT_FIELDS}
  query GetPosts($teamId: String!, $clubId: String, $eventType: String, $fromDate: String, $toDate: String, $skip: Int = 0, $limit: Int = 10) {
    posts(teamId: $teamId, clubId: $clubId, eventType: $eventType, fromDate: $fromDate, toDate: $toDate, skip: $skip, limit: $limit) {
      ...eventFields
    }
  }
`;
