export const CREATE_ROOM = `
  mutation CreateRoom($name: String!) {
      createRoom(name: $name) 
  }
`;

export const ADD_USER_TO_ROOM = `
  mutation AddUserToRoom($userId: String!, $roomId: String!) {
      addUserToRoom(userId: $userId, roomId: $roomId) 
  }
`;

export const REMOVE_USER_FROM_ROOM = `
  mutation RemoveUserFromRoom($userId: String!, $roomId: String!) {
      removeUserFromRoom(userId: $userId, roomId: $roomId) 
  }
`;
