import { DateTime } from "luxon";

export const isEmpty = (value = "") => !value;
export const isEmptyArray = (value = []) => value.length <= 0;
export const isEmptyObject = (value = {}) => !(Object.keys(value).length > 0);
export const isInvalidDate = (value = "") => {
  if (!isEmpty(value)) {
    const date = DateTime.fromISO(value);
    return !date.isValid;
  }

  return false;
};
export const isBeforeNow = (date = DateTime.now()) => {
  if (!isEmpty(date) && !isInvalidDate(date)) {
    return date.startOf("day") < DateTime.now().startOf("day");
  }

  return false;
};
export const inNotZero = (value = 0) => value !== 0;
export const isInvalidEmail = (value = "") =>
  value.length > 0 &&
  !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
export const isTimeAfterTime = (timeToBeCompared, time) => {
  const timeToBeComparedDateTime = DateTime.fromISO(timeToBeCompared);
  const timeDateTime = DateTime.fromISO(time);
  return timeToBeComparedDateTime > timeDateTime;
};
export const isTimeBeforeTime = (timeToBeCompared, time) => {
  const timeToBeComparedDateTime = DateTime.fromISO(timeToBeCompared);
  const timeDateTime = DateTime.fromISO(time);
  return timeToBeComparedDateTime < timeDateTime;
};
export const isInvalidTime = (value = "") => {
  return isInvalidDate(value);
};
export const formValidator = ({ schema, values }) => {
  const errors = {};
  schema
    .filter((field) => !field.hidden)
    .map((field) => {
      if (field.validators) {
        field.validators.map(({ validator, text, dependency }) => {
          if (dependency) {
            if (validator(values[field.name], values[dependency])) {
              errors[field.name] = text(field.label);
            }
          } else {
            if (validator(values[field.name])) {
              errors[field.name] = text(field.label);
            }
          }
        });
      }
    });
  return errors;
};
