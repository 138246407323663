import React, { useContext, useState, useEffect } from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Form from "../../Components/Form/Form";
import TextFieldRenderer from "../../Components/Form/TextFieldRenderer";
import { isEmpty } from "../../Components/Form/validators";
import { css } from "@emotion/css";
import SelectRenderer from "../../Components/Form/SelectRenderer";
import { GET_CLUBS_SA, REGISTER_USER_SA } from "./SuperAdminService";
import { SnackBarContext } from "../../context/SnackBarContext";
import Autocomplete from "../../Components/Autocomplete/Autocomplete";
import { useTranslation } from "../../context/Internationalization";
import DrawerFormTitle from "../../Components/Common/DrawerFormTitle/DrawerFormTitle";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";

const DrawerFormStyles = css`
  .wrapper {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
  }
`;

const SearchSelectRenderer = (props) => {
  return <SelectRenderer props />;
};

const schema = (selectedRole, t) => [
  {
    label: t("firstName"),
    name: "firstName",
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    renderer: TextFieldRenderer,
    row: 1,
  },
  {
    label: t("lastName"),
    name: "lastName",
    renderer: TextFieldRenderer,
    row: 2,
  },
  {
    label: t("mobile"),
    name: "mobile",
    renderer: TextFieldRenderer,
    row: 3,
  },
  {
    label: t("role"),
    name: "role",
    options: [
      { text: t("coach"), value: "coach" },
      { text: t("team_admin"), value: "team-admin" },
      { text: t("club_admin"), value: "club-admin" },
    ],
    renderer: SelectRenderer,
    row: 4,
  },
  selectedRole === "club-admin" && {
    label: "Club ID",
    name: "clubId",
    renderer: (props) => (
      <Autocomplete
        {...props}
        key={"clubId"}
        placeholder={"Search Club"}
        query={GET_CLUBS_SA}
        showSearchListResults
        onChangeName={"clubId"}
        mapResults={(results) => {
          return results?.getClubsSA?.map((club) => {
            return { text: club.name, value: club._id };
          });
        }}
      />
    ),
    row: 5,
  },
  (selectedRole === "coach" || selectedRole === "team-admin") && {
    label: "Team ID",
    name: "teamId",
    renderer: TextFieldRenderer,
    row: 6,
  },
];

const initialState = {
  firstName: "",
  lastName: "",
  mobile: "",
  role: "",
  clubId: "",
  teamId: "",
};

const RegisterUserDrawer = ({ openDrawer, onCloseDrawer }) => {
  const [values, setValues] = useState(initialState);
  const snackBar = useContext(SnackBarContext);
  const [term, setTerm] = useState("");
  const [clubs, setClubs] = useState([]);
  const { t } = useTranslation();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();

  useEffect(() => {
    if (values.role === "club-admin") {
      setValues({ ...values, teamId: "" });
    } else {
      setValues({ ...values, clubId: "" });
    }
  }, [values.role]);

  const getRolesTranslated = (role, isError) => {
    switch (role) {
      case "club-admin":
        return isError ? t("error_creating_club_admin") : t("the_club_admin");
      case "team-admin":
        return isError ? t("error_creating_team_admin") : t("the_team_admin");
      case "coach":
        return isError ? t("error_creating_coach") : t("the_coach");
    }
  };

  const handleSubmit = () => {
    axiosGraphQL({ query: REGISTER_USER_SA, variables: values })
      .then(async () => {
        snackBar.setOptions({
          alertMessage: `${getRolesTranslated(values.role)} ${t(
            "created_successfully"
          )}`,
          alertSeverity: "success",
        });
        snackBar.setSnackbarOpen(true);
      }).catch((error) => {
        errorLog(error, "RegisterUserDrawer", "handleSubmit", "REGISTER_USER_SA");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  const onChange = ({ target: { name, value } }) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Drawer anchor="right" open={openDrawer} onClose={onCloseDrawer}>
      <Box className={DrawerFormStyles}>
        <Box className="wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton onClick={onCloseDrawer}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ maxWidth: "400px" }}>
            <DrawerFormTitle
              actionText={t("register")}
              className="grid-container-header"
              text={t("create_user")}
            />
            <Grid item xs={12}>
              <Form
                schema={schema(values.role, t)}
                values={values}
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 24 }}>
              <Button onClick={handleSubmit} fullWidth>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default RegisterUserDrawer;
