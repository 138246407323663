import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loader = props => {

    return (
        <Box component="main" sx={{flexGrow: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <CircularProgress
                    sx={{
                        "--CircularProgress-track-thickness": "22px",
                        "--CircularProgress-progress-thickness": "20px",
                        "--CircularProgress-size": "90px"
                    }}
                />
            </div>

        </Box>
    );
};

Loader.propTypes = {

};

export default Loader;
