import { PERSON_RESOLVED_FIELDS } from "../../graphql/userGraphql";
import {EVENT_FIELDS} from "../Activities/EventsService";

export const TEAM_FIELDS = `
  fragment teamFields on Team {
    _id
    name
    photo
    sport
    ageGroup
    season
    club
    teamAdminUsers
    coaches
    teamCode
    leagues
    isClaimed
    isOfficial
    gender
  }
`;

export const TEAM_FIELDS_WITH_STAFF = `
  ${PERSON_RESOLVED_FIELDS}
  fragment teamFieldsWithStaff on TeamWithStaff {
    _id
    name
    photo
    sport
    ageGroup
    season
    club
    teamAdminUsers
    coaches
    teamCode
    leagues
    isClaimed
    isOfficial
    gender
    coachesInfo {
      ...personResolvedFields
    },
    teamAdminsInfo {
      ...personResolvedFields
    }
  }
`;

export const GET_TEAM_DETAILS = `
  ${TEAM_FIELDS_WITH_STAFF}
   query ClubTeamsWithStaff($teamIds: [String]!, $skip: Int, $limit: Int) {
    teamDetails(teamIds: $teamIds, skip: $skip, limit: $limit) {
      ...teamFieldsWithStaff
    }
  }
`;

export const GET_TEAMS = `
  query GetTeamsForPosts ($clubId: String!, $skip: Int, $limit: Int) {
    ClubTeams(clubId: $clubId, skip: $skip, limit: $limit) {
      _id
      name
      ageGroup
      teamCode
      teamAdminUsers
      coaches
    }
  }
`;

export const GET_CLUBS_TEAMS_ALL_FIELDS = `
  ${TEAM_FIELDS}
  query GetClubsTeams($clubId: String!, $skip: Int, $limit: Int, $sortingDir: String, $sortingField: String) {
    ClubTeams(clubId: $clubId, skip: $skip, limit: $limit, sortingDir: $sortingDir, sortingField: $sortingField) {
      ...teamFields
    }
  } 
`;

export const CREATE_TEAM = `
  mutation CreateClubTeam(
    $name: String!,
    $sport: String!,
    $clubId: String,
    $photo: String,
    $gender: String,
    $ageGroup: String) {
      createTeam(name: $name,
        sport: $sport,
        clubId: $clubId,
        photo: $photo,
        ageGroup: $ageGroup,
        gender: $gender
      ) {
        _id
      }
    }
`;

export const UPDATE_TEAM = `
   mutation UpdateTeam(
    $teamid: String!,
    $name: String,
    $ageGroup: String) {
      editTeam(teamid: $teamid,
        name: $name,
        ageGroup: $ageGroup
      ) {
        _id
      }
    }
`;

export const UPLOAD_TEAM_IMAGE = `
  mutation ($uploadType: String!, $itemId: String!, $fileName: String!) {
  uploadImage(uploadType: $uploadType, itemId: $itemId, fileName: $fileName)
}
`;

export const DELETE_TEAM = `
  mutation ($teamId: String!) {
  deleteTeam(teamId: $teamId)
}
`;

export const DELETE_PROFILE = `
  mutation ($profileId: String!) {
  deleteProfile(profileId: $profileId)
}
`;

export const REMOVE_PLAYER_FROM_PROFILE = `
  mutation ($profileId: String!) {
  removePlayerFromProfile(profileId: $profileId) {
    _id
  }
}
`;

export const GET_PROFILE_ATTENDANCE = ` 
 ${EVENT_FIELDS}
  query ($profileId: String!, $fromDate: String!, $toDate: String!, $eventType: String) {
  getProfileAttendance(profileId: $profileId, fromDate: $fromDate, toDate: $toDate, eventType: $eventType) {
    events {
      ...eventFields
    }
    totalEvents
    attendedEvents
    notAttended
    totalAttendance
  }
}
`;

export const GET_TEAM_ATTENDANCE = ` 
 ${EVENT_FIELDS}
  query ($teamId: String!, $fromDate: String!, $toDate: String!, $eventType: String) {
  getTeamAttendances(teamId: $teamId, fromDate: $fromDate, toDate: $toDate, eventType: $eventType) {
   profileId
   attendances {
    events {
    ...eventFields
    }
    totalEvents
    attendedEvents
    notAttended
    totalAttendance
   }
  }
}
`;
