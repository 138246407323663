const useLog = () => {
  const log = (message) => {
    process.env.REACT_APP_BASE_URL.includes("dev") && console.log(message);
  };

  const extendedLog = (message, className, functionName) => {
    process.env.REACT_APP_BASE_URL.includes("dev") &&
      console.log(`[${className}.js] - ${functionName} => `, message);
  };

  const errorLog = (error, className, functionName, queryName) => {
    if (process.env.REACT_APP_BASE_URL.includes("dev")) {
      const errorMessage = error?.message || error;

      console.error(
        `[${className}.js] - ${functionName} ${queryName} Error => `,
        JSON.stringify(errorMessage)
      );
    }
  };

  return { log, extendedLog, errorLog };
};

export default useLog;
