import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import GbFlag from '../assets/images/flags/GB.svg';
import GrFlag from '../assets/images/flags/GR.svg';
import {useTranslation} from "../context/Internationalization";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    marginRight: 0,
    '& .MuiSwitch-switchBase': {
        margin: 0,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            margin: 1,
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('${GrFlag}')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('${GbFlag}')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));
const TranslationToggle = props => {
    const {switchLanguage, lang} = useTranslation()

    const onChangeLanguage = () => {
        switchLanguage(lang === 'gr' ? 'en': 'gr')
    }

    return (
        <FormGroup>
            <FormControlLabel  control={<MaterialUISwitch sx={{ m: 1 }} checked={lang === 'gr'} />} onChange={onChangeLanguage}/>
        </FormGroup>
    );
};

TranslationToggle.propTypes = {

};

export default TranslationToggle;
