import { configureStore } from '@reduxjs/toolkit';
import officialLeagueReducer from '../pages/SuperAdmin/OfficialLeagues/OfficialLeagueSlice';
import formsReducer from '../pages/Forms/formSlice';

export const store = configureStore({
    reducer: {
        officialLeague: officialLeagueReducer,
        forms: formsReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['officialLeague/updateOfficialLeagueDetails/fulfilled','officialLeague/updateFixtureDetails/fulfilled','officialLeague/updateMatchDetails/fulfilled'],
            },
        }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export const RootState = store.getState
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export const AppDispatch =  store.dispatch
