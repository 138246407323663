import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from "../../context/Internationalization";
import {AuthContext} from "../../context/AuthContext";
import { GET_USER_PROFILES_SA, SEARCH_USER_WITH_MOBILE_SA} from "./SuperAdminService";
import {Box, Button, Grid} from "@mui/material";
import boxStyles from "../../styles/boxing";
import Stack from "@mui/material/Stack";
import MainTitle from "../../Components/Common/MainTitle/MainTitle";
import Divider from "@mui/material/Divider";
import TextFieldRenderer from "../../Components/Form/TextFieldRenderer";
import ClubsTable from "./ClubsTable/ClubsTable";
import ClubsDrawer from "./ClubsTable/ClubsDrawer";
import RegisterUserDrawer from "./RegisterUserDrawer";
import EditIcon from "@mui/icons-material/Edit";
import {GET_RESOLVE_USERS, GET_USER, SEARCH_USER_WITH_MOBILE} from "../../graphql/userGraphql";
import UsersTable from "./UsersTable/UsersTable";
import ImpersonateConfirmationModal from "./UsersTable/ImpersonateConfirmationModal";
import {useSuperAdmin} from "../../context/SuperAdminContext";
import {getStorage} from "../../auth/utils";
import {useNavigate} from "react-router-dom";
import Form from "../../Components/Form/Form";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import useStyles from "../Forms/formsStyles";
import ProfilesTable from "./ProfilesTable/ProfilesTable";
import TeamsTable from "./TeamsTable/TeamsTable";
import UserInvitationsTable from "./UserInvitations/UserInvitationsTable";
import UserClubTable from "./UserClubTable/UserClubTable";
import {SnackBarContext} from "../../context/SnackBarContext";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";

const schema = () =>[
    {
        name: 'firstName',
        label: 'First Name',
        disabled: true,
        renderer: TextFieldRenderer,
        row: 1
    },
    {
        name: 'lastName',
        label: 'Last Name',
        disabled: true,
        renderer: TextFieldRenderer,
        row: 1
    },
    {
        name: 'mobile',
        label: 'Mobile',
        disabled: true,
        renderer: TextFieldRenderer,
        row: 1
    },
]

const initialState = {
    user: null,
    loading: false,
};

const tableMenuActions = (impersonate) => {
    return [
        {
            label: "Impersonate User",
            icon: <EditIcon fontSize="small" />,
            onClickAction: impersonate,
        },

    ];
};

const SuperAdminImpersonate = props => {
    const { t } = useTranslation();
    const [state, setState] = useState(initialState);
    const [filters, setFilters] = useState({id: '', mobile: ''});
    const { authState, setImpersonate } = useContext(AuthContext);
    const club = authState.user.club
    const {setUser} = useSuperAdmin()
    const navigate = useNavigate()
    const styles = useStyles()
    const snackBar = useContext(SnackBarContext);
    const {axiosGraphQL} = useGraphql()
    const {errorLog} = useLog();

    const handleAction = () => {
        setState({ ...state, impersonateModal: true});
    };

    const resolveUser = () => {
        setState((previousState) => ({ ...previousState, loading: true }));
        if(filters.mobile) {
            axiosGraphQL({
                query: SEARCH_USER_WITH_MOBILE_SA,
                variables: {mobile: filters.mobile},
            }).then(response => {
                const user = response?.data?.data?.searchUserWithMobileSA && response?.data?.data?.searchUserWithMobileSA[0]
                if(user) {
                    axiosGraphQL({
                        query: GET_USER_PROFILES_SA,
                        variables: {userId: user?._id}
                    }).then(response => {
                        setState({user: user, profiles: response?.data?.data?.getClubProfilesSA});
                    })

                }
            }).catch((error) => {
                errorLog(error, "SuperAdminImpersonate", "resolveUser", "SEARCH_USER_WITH_MOBILE_SA");
                setState(prevState => ({...prevState, loading: false}));
                snackBar.setOptions({
                    alertMessage: error.message,
                    alertSeverity: "error",
                });
                snackBar.setSnackbarOpen(true);
            });
        } else {
            axiosGraphQL({
                query: GET_RESOLVE_USERS,
                variables: {
                    ids: [filters.id],
                    skip: 0,
                    limit: 20,
                },
            }).then((response) => {
                const { resolveUserIds } = response.data.data;
                setState({user: resolveUserIds && resolveUserIds[0]});
            }).catch((error) => {
                errorLog(error, "SuperAdminImpersonate", "resolveUser", "GET_RESOLVE_USERS");
                setState(prevState => ({...prevState, loading: false}));
                snackBar.setOptions({
                    alertMessage: error.message,
                    alertSeverity: "error",
                });
                snackBar.setSnackbarOpen(true);
            });
        }
    };

    const onChangeFilter = ({target: {name, value}}) => {
        setFilters(prevState => ({...prevState, [name]: value}))
    }


    const onImpersonate = () => {
        setUser(state.user)
        setTimeout(()=> {
            axiosGraphQL({ query: GET_USER }).then((response) => {
                if (response.data.data.me.club && response.data.data.me.user) {
                    const user = response.data.data.me.user;
                    user.club = response.data.data.me.club;
                    getStorage().setItem('impersonate', JSON.stringify(user))
                    setImpersonate(user)
                    navigate('/news')
                }
            }).catch((error) => {
                errorLog(error, "SuperAdminImpersonate", "onImpersonate", "GET_USER");
                snackBar.setOptions({
                    alertMessage: error.message,
                    alertSeverity: "error",
                });
                snackBar.setSnackbarOpen(true);
            });
        }, 100)

    }

    const onCloseModal = () => {
        setState({ ...state, impersonateModal: false});
    }

    return ( <Box
            component="main"
            sx={{flexGrow: 1, p: 3}}>
            <Box>
                <ImpersonateConfirmationModal open={state.impersonateModal} onClose={onCloseModal}
                                              onImpersonate={onImpersonate}/>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={boxStyles.headerContainer} component="div">
                            <Stack direction={'column'} spacing={1}>
                                <MainTitle title={`Admin Panel`}/>
                            </Stack>
                        </Box>
                        <Divider style={{width: '100%'}}/>

                    </Grid>
                    <Grid item xs={12} style={boxStyles.filtersContainer}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Stack direction={'row'} spacing={1}>
                                <TextFieldRenderer label={'Id'} onChange={onChangeFilter} name={'id'} value={filters.id}/>
                                <TextFieldRenderer label={'Mobile'} onChange={onChangeFilter} name={'mobile'} value={filters.mobile}/>
                                <Button type="button" size="small" onClick={resolveUser}>
                                    Search User
                                </Button>
                            </Stack>
                        </Stack>

                    </Grid>
                </Grid>


                {state.user && <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <Button onClick={handleAction}>Impersonate</Button>
                        </Stack>
                        <Form schema={schema()} values={state.user || {}} />
                    </Grid>
                    <Grid  item xs={12}>
                        <Accordion  className={styles.cardContainer}>
                            <AccordionSummary
                                expandIcon={null}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                Clubs
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserClubTable data={state.user?.clubs || []}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion  className={styles.cardContainer}>
                            <AccordionSummary
                                expandIcon={null}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                Teams
                            </AccordionSummary>
                            <AccordionDetails>
                                <TeamsTable data={state?.user?.teams.map(team => ({...team, userId: state.user?._id}))}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion  className={styles.cardContainer}>
                            <AccordionSummary
                                expandIcon={null}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                User Invitations
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserInvitationsTable data={state?.user?.invitations}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion  className={styles.cardContainer}>
                            <AccordionSummary
                                expandIcon={null}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                Profiles
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProfilesTable data={state.profiles ? state.profiles : []}/>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>}
            </Box>
        </Box>
    );
};

SuperAdminImpersonate.propTypes = {

};

export default SuperAdminImpersonate;
