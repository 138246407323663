import React from "react";
import Table from "../../../Components/Table/Table";
import ProfilesTableRow from "./ProfilesTableRow";

const schema = [
  {
    id: '',
    label: ''
  },
  {
    id: 'firstName',
    label: 'First Name'
  },
  {
    id: 'lastName',
    label: 'Last Name'
  },
  {
    id: 'team',
    label: 'Team'
  },
  {
    id: 'club',
    label: 'Club'
  }
]

const ProfilesTable = ({ data, menuActions = [], lastElementRef }) => {
  const rowElement = (dato, index) => <ProfilesTableRow
      key={dato._id}
      lastElementRef={lastElementRef}
      data={dato}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={data} columns={schema} rowElement={rowElement}/>);
};

export default ProfilesTable;
