import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalActions} from "../../../Components/Modal";
import {Button} from "@mui/material";
import ModalTitle from "../../../Components/Modal/ModalTitle";
import ModalBody from "../../../Components/Modal/ModalBody";
import {useTranslation} from "../../../context/Internationalization"


const ImpersonateConfirmationModal = ({open, onClose, onImpersonate}) => {
  const {t} = useTranslation()

  return (
      <Modal open={open} onClose={onClose}>
        <ModalTitle title={'Impesonate'}/>
        <ModalBody>
          <span>Impersonate user?<br/> </span>
        </ModalBody>
        <ModalActions>
          <Button variant={'outlined'} color={'primary'} fullWidth onClick={onClose}>{t('close')}</Button>
          <Button variant={'outlined'} color={'error'} fullWidth onClick={onImpersonate}>
            {t('ok')}
          </Button>
        </ModalActions>

      </Modal>
  );
};

ImpersonateConfirmationModal.propTypes = {

};

export default ImpersonateConfirmationModal;
