import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  memo,
} from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { css } from "@emotion/css";
import { AuthContext } from "../../../context/AuthContext";
import { SnackBarContext } from "../../../context/SnackBarContext";
import IconButton from "@mui/material/IconButton";
import {
  CREATE_CLUB,
  EDIT_CLUB_SA,
  REMOVE_CLUB_ADMIN_SA,
} from "../SuperAdminService";
import Form from "../../../Components/Form/Form";
import { isEmpty } from "../../../Components/Form/validators";
import TextFieldRenderer from "../../../Components/Form/TextFieldRenderer";
import ToggleRenderer from "../../../Components/Form/ToggleRenderer";
import SelectRenderer from "../../../Components/Form/SelectRenderer";
import Stack from "@mui/material/Stack";
import DeletePlayerConnectionIcon from "../../../assets/images/Svg/DeletePlayerConnectionIcon";
import { makeStyles } from "@mui/styles";
import { colors } from "../../../theme/colors";
import { fonts } from "../../../theme/fonts";
import { GET_RESOLVE_USERS } from "../../../graphql/userGraphql";
import RemoveClubAdminConfirmationModal from "./RemoveClubAdminConfirmationModal";
import { countries } from "../../../utility/Enum";
import { useTranslation } from "../../../context/Internationalization";
import DrawerFormTitle from "../../../Components/Common/DrawerFormTitle/DrawerFormTitle";
import useGraphql from "../../../Hooks/useGraphql";
import useLog from "../../../Hooks/useLog";

const useStyles = makeStyles({
  phoneContainer: {
    width: "100%",
    margin: "8px 0",
    padding: "8px 16px",
    background: colors.Base.White,
    border: `1px solid ${colors.Grey[300]}`,
    boxShadow: `0px 1px 2px rgba(22, 31, 37, 0.05)`,
    borderRadius: "8px",
  },
  phoneComponentName: {
    ...fonts.text.md.normal,
    color: colors.Grey[900],
  },
  phoneComponentNumber: {
    ...fonts.text.md.normal,
    color: colors.Grey[500],
  },
  sectionTitle: {
    ...fonts.text.sm.medium,
    color: colors.Grey[700],
  },
});

const DrawerFormStyles = css`
  .wrapper {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
  }
`;

const schema = (selectedClub, t) => [
  {
    label: t("club_name"),
    name: "name",
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    renderer: TextFieldRenderer,
    row: 1,
  },
  {
    label: t("founded"),
    name: "founded",
    renderer: TextFieldRenderer,
    row: 3,
  },
  {
    label: t("country"),
    name: "country",
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    options:
      countries?.map((country) => ({
        value: country.label,
        text: country.label,
      })) || [],
    renderer: SelectRenderer,
    row: 4,
  },
  {
    label: t("Premium"),
    name: "isPremium",
    disabled: !selectedClub || Object.entries(selectedClub).length === 0, // to be removed when create club api has also isPremium as input
    renderer: ToggleRenderer,
    row: 5,
  },
  // {
  //   label: 'Location',
  //   name: 'location',
  //   disabled: selectedClub,
  //   renderer: LocationRenderer,
  //   row: 6
  // },
];

const initialState = {
  name: "",
  founded: "",
  country: "Greece",
  isPremium: false,
  location: undefined,
  clubAdminUsers: [],
};

const populateData = (club) => {
  const _initialState = { ...initialState };
  if (club) {
    _initialState.name = club.name;
    _initialState.founded = club.founded || "";
    _initialState.country = club.country;
    _initialState.isPremium = club.isPremium;
    _initialState.location = {
      address: club.location?.address,
      lat: club.location?.coordinates[1],
      lng: club.location?.coordinates[0],
    };
    _initialState.clubAdminUsers = club.clubAdminUsers;
  }

  return _initialState;
};
const ClubsDrawer = ({ openDrawer, onCloseDrawer, selectedClub, getClubs }) => {
  const { authState } = useContext(AuthContext);
  const snackBar = useContext(SnackBarContext);
  const clubId = authState.user.club?._id;
  const [values, setValues] = useState(populateData(selectedClub));
  const [removeModal, setRemoveModal] = useState(false);
  const { t } = useTranslation();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();

  const styles = useStyles();

  useEffect(() => {
    if (selectedClub) {
      setValues(populateData(selectedClub));
    } else {
      setValues(initialState);
    }
  }, [selectedClub?._id]);
  const onClose = () => {
    onCloseDrawer();
  };

  const handleSubmit = () => {
    let query = CREATE_CLUB;
    let variables = {
      name: values.name,
      founded: +values.founded,
      country: values.country,
      // location: {
      //   type: "Point",
      //   address: values.location.address,
      //   coordinates: [values.location.lng, values.location.lat],
      // }
    };

    let alertMessage = t("academyCreatedSuccessfully");
    if (selectedClub) {
      query = EDIT_CLUB_SA;
      alertMessage = t("academyUpdatedSuccessfully");
      variables = {
        clubId: selectedClub._id,
        name: values.name,
        founded: +values.founded || 0,
        country: values.country || "",
        isPremium: values.isPremium ? "true" : "false",
      };
    }

    axiosGraphQL({ query, variables })
      .then(async () => {
          snackBar.setOptions({
            alertMessage,
            alertSeverity: "success",
          });
          snackBar.setSnackbarOpen(true);
          await getClubs();
          onClose();
      }).catch((error) => {
        errorLog(error, "ClubsDrawer", "handleSubmit", Object.keys({ query })[0]);
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
    });
  };

  const _schema = schema(selectedClub, t);

  const onChange = ({ target: { name, value } }) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleRemoveClubAdmin = useCallback(() => {
    axiosGraphQL({
      query: REMOVE_CLUB_ADMIN_SA,
      variables: {
        clubId: selectedClub._id,
        userId: removeModal?._id,
        deleteUser: false,
      },
    })
      .then((response) => {
          snackBar.setOptions({
            alertMessage: t("club_admin_successfully_removed"),
            alertSeverity: "success",
          });
          snackBar.setSnackbarOpen(true);
          openCloseModal();
          getClubs();
          onClose();
      }).catch((error) => {
          errorLog(error, "ClubsDrawer", "handleRemoveClubAdmin", "REMOVE_CLUB_ADMIN_SA");
          snackBar.setOptions({
              alertMessage: error.message,
              alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
      });
  }, [selectedClub?._id, removeModal?._id]);

  const openRemoveModal = (user) => {
    setRemoveModal(user);
  };
  const openCloseModal = () => {
    setRemoveModal(false);
  };
  return (
    <Drawer anchor="right" open={openDrawer} onClose={onClose}>
      <RemoveClubAdminConfirmationModal
        open={removeModal}
        onClose={openCloseModal}
        onRemove={handleRemoveClubAdmin}
      />
      <Box className={DrawerFormStyles}>
        <Box className="wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton onClick={onClose}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ maxWidth: "400px" }}>
            <DrawerFormTitle
              actionText={selectedClub ? t("update") : t("create")}
              className="grid-container-header"
              text={t("create_club")}
            />
            <Grid item xs={12}>
              <Form
                schema={_schema}
                values={values}
                onChange={onChange}
                errors={values.errors}
              />
              {selectedClub && Object.entries(selectedClub).length > 0 ? (
                <Grid item xs={12} style={{ paddingTop: "24px" }}>
                  <span className={styles.sectionTitle}>
                    {t("club_admins")}
                  </span>
                  {values.clubAdminUsers?.map((userId) => (
                    <PhoneComponents
                      userId={userId}
                      clubId={clubId}
                      onRemoveClubAdmin={openRemoveModal}
                      axiosGraphQL={axiosGraphQL}
                      errorLog={errorLog}
                      snackBar={snackBar}
                    />
                  ))}
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 24 }}>
              <Button onClick={handleSubmit} fullWidth>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

const PhoneComponents = memo(({ userId, onRemoveClubAdmin, axiosGraphQL, errorLog, snackBar }) => {
  const styles = useStyles();
  const [user, setUser] = useState({
    _id: userId,
    firstName: "",
    lastName: "",
    phone: "",
  });

  useEffect(() => {
    axiosGraphQL({
      query: GET_RESOLVE_USERS,
      variables: { ids: [userId] },
    })
      .then((response) => {
        const { resolveUserIds } = response.data.data;
        setUser(resolveUserIds[0]);
      }).catch((error) => {
        errorLog(error, "ClubsDrawer", "useEffect", "GET_RESOLVE_USERS");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  }, []);

  const handleRemoveClubAdmin = useCallback(() => {
    onRemoveClubAdmin(user);
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      className={styles.phoneContainer}
    >
      <Grid container spacing={1}>
        <Grid item className={styles.phoneComponentName}>
          {user?.firstName} {user?.lastName}
        </Grid>
        <Grid item className={styles.phoneComponentNumber}>
          {user?.mobile}
        </Grid>
      </Grid>
      <IconButton color={"neutral"} onClick={handleRemoveClubAdmin}>
        <DeletePlayerConnectionIcon />
      </IconButton>
    </Stack>
  );
});

export default ClubsDrawer;
