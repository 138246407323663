import React, { createContext, useState } from "react";
import SnackBar from "../Components/Common/SnackBar/SnackBar";

const SnackBarContext = createContext();
const { Provider } = SnackBarContext;

const SnackBarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [options, setOptions] = useState({
    autoHideDuration: 6000,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertSeverity: "success",
  });
  const [errorOptions, setErrorOptions] = useState({
    autoHideDuration: 6000,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertSeverity: "error",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const setSnackBarAndAlertOptions = (snackBarOptions) => {
    setOptions({
      ...options,
      ...snackBarOptions,
    });
  };
  const setErrorSnackBarAndAlertOptions = (snackBarOptions) => {
    setErrorOptions({
      ...errorOptions,
      ...snackBarOptions,
    });
  };

  const handleOpenSnackbar = (open) => {
    if(open) {
      setTimeout(() => {
        setOpen(false)
      },4000)
    }
   setOpen(open)
  }

  const handleOpenErrorSnackbar = (open) => {
    if(open) {
      setTimeout(() => {
        setErrorOpen(false)
      },4000)
    }
    setErrorOpen(open)
  }

  return (
    <Provider
      value={{
        setSnackBarErrorOpen: handleOpenErrorSnackbar,
        setSnackbarOpen: handleOpenSnackbar,
        handleCloseSnackbar: (event, reason) =>
          handleCloseSnackbar(event, reason),
        setOptions: (snackBarOptions) =>
          setSnackBarAndAlertOptions(snackBarOptions),
        setErrorOptions: (snackBarOptions) =>
            setErrorSnackBarAndAlertOptions(snackBarOptions),
      }}
    >
      {children}
      <SnackBar open={open} options={options} onClose={handleCloseSnackbar} />
      <SnackBar open={errorOpen} options={errorOptions} onClose={handleCloseSnackbar} />
    </Provider>
  );
};

export { SnackBarContext, SnackBarProvider };
