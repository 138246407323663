import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalActions} from "../../../Components/Modal";
import {Button} from "@mui/material";
import ModalTitle from "../../../Components/Modal/ModalTitle";
import ModalBody from "../../../Components/Modal/ModalBody";
import {useTranslation} from "../../../context/Internationalization"


const RemoveClubAdminConfirmationModal = ({open: user, onClose, onRemove}) => {
    const {t} = useTranslation()

    return (
        <Modal open={user} onClose={onClose}>
            <ModalTitle title={'Delete User'}/>
            <ModalBody>
                <span>{`Are you sure you want to delete user ${user?.firstName} ${user?.lastName} with mobile ${user?.mobile}`}?<br/> </span>
            </ModalBody>
            <ModalActions>
                <Button variant={'outlined'} color={'primary'} fullWidth onClick={onClose}>{t('no')}</Button>
                <Button variant={'outlined'} color={'error'} fullWidth onClick={onRemove}>
                    {t('yes')}
                </Button>
            </ModalActions>

        </Modal>
    );
};

RemoveClubAdminConfirmationModal.propTypes = {

};

export default RemoveClubAdminConfirmationModal;
