import React from "react";
import { IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "../Icon/Icon";

const menuPaperProps = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.12))",
  boxShadow: "none",
  mt: 1.5,
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: "background.paper",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0,
  },
};

const TableActionsMenu = ({
    icon,
  actions,
  handleClickAction,
  handleCloseActionMenu,
  rowData,
  open,
  anchorEl,
}) => {


  return (
    <>
      <IconButton
        onClick={(event) => handleClickAction(event, rowData)}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {icon ? <Icon src={icon} alt={'menu icon'}/>:<MoreVertIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseActionMenu}
        onClick={handleCloseActionMenu}
        PaperProps={{
          sx: menuPaperProps,
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {actions.map((action) => {
          const isDisabled = action.disabled || false;

          return (
            <MenuItem
              key={action.label}
              onClick={(e) => !isDisabled && action.onClickAction(rowData, e)}
              disabled={isDisabled}
            >
              <ListItemIcon>{action.icon}</ListItemIcon>
              {action.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default TableActionsMenu;
