import React, { lazy, useContext, useEffect } from "react";
import { BrowserRouter as Router, Navigate, useRoutes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MultiBackend } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch"; // or any other pipeline

import AppShell from "./AppShell";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import TranslationProvider from "./context/Internationalization";
import { SnackBarProvider } from "./context/SnackBarContext";
import Club from "./pages/Club/Club";
import Login from "./pages/Login/Login";
import MessagesProvider from "./context/MessagesContext";
import SuperAdminClubs from "./pages/SuperAdmin/SuperAdminClubs";
import { isSuperAdmin } from "./utility/Utils";
import InvitationProvider from "./context/InvitationContext";
import SuperAdminProvider from "./context/SuperAdminContext";
import SuperAdminImpersonate from "./pages/SuperAdmin/SuperAdminImpersonate";
import OfficialLeagues from "./pages/SuperAdmin/OfficialLeagues/OfficialLeagues";
import { AppProvider } from "./context/AppContext";

const Home = lazy(() => import("./pages/Home/Home"));
const Messages = lazy(() => import("./pages/Messages/Messages"));
const Shop = lazy(() => import("./pages/Shop/Shop"));
const Forms = lazy(() => import("./pages/Forms/Forms"));

function App() {
  const auth = useContext(AuthContext);

  const user = auth.authState.user;

  const isUserSuperAdmin = isSuperAdmin(user);
  let routes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: auth.isAuthenticated() ? (
        <Navigate to="/news" />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/*",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <Home />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/club/*",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <Club />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/messages/*",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <Messages />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/shop/*",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <Shop />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/forms/*",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <Forms />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/super-admin/clubs",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <SuperAdminClubs />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/super-admin/official-leagues/*",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <OfficialLeagues />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/super-admin/impersonate",
      element: auth.isAuthenticated() ? (
        <AppShell>
          <SuperAdminImpersonate />
        </AppShell>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ];
  if (!isUserSuperAdmin) {
    routes = routes
      .filter((route) => route.path !== "/super-admin/clubs")
      .filter((route) => route.path !== "/super-admin/impersonate");
  }

  return (
    <SuperAdminProvider isUserSuperAdmin={isUserSuperAdmin}>
      {useRoutes(routes)}
    </SuperAdminProvider>
  );
}

const AppWrapper = () => {
  return (
    <div>
      <Router>
        {/*<ScrollToTop />*/}
        <div className="Container">
          <AuthProvider>
            <AppProvider>
              <TranslationProvider>
                <MessagesProvider>
                  <SnackBarProvider>
                    <InvitationProvider>
                      <DndProvider
                        backend={MultiBackend}
                        options={HTML5toTouch}
                      >
                        <App />
                      </DndProvider>
                    </InvitationProvider>
                  </SnackBarProvider>
                </MessagesProvider>
              </TranslationProvider>
            </AppProvider>
          </AuthProvider>
        </div>
      </Router>
    </div>
  );
};

export default AppWrapper;
