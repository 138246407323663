import React from 'react';
import MuiModal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import ModalHeader from "./ModalHeader";
import useStyles from "./modalStyles";


const Modal = ({open, onClose, children, type}) => {
    const styles = useStyles()

    return (
        <MuiModal open={open} onClose={onClose} type={type}>
            <Box className={styles.modalContainer}>
                <ModalHeader onClose={onClose} type={type}/>
                <Box className={styles.contentContainer}>
                 {children}
                </Box>
            </Box>
        </MuiModal>
    );
};

Modal.propTypes = {

};

export default Modal;
