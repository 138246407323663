import React from "react";
import { Typography } from "@mui/material";

const MainTitle = ({ title }) => {
  return (
    <Typography variant="h5">
      {title}
    </Typography>
  );
};

export default MainTitle;
