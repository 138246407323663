import React from "react";
import { useTranslation } from "../../../context/Internationalization";
import Table from "../../../Components/Table/Table";
import ClubsTableRow from "./ClubsTableRow";

const schema = (t) => [
  {
    id: 'name',
    label: t('club_name')
  },
  {
    id: 'country',
    label: t('country')
  },
  {
    id: 'founded',
    label: t('founded')
  },
  {
    id: 'websiteUrl',
    label: t('website')
  },
  {
    id: 'isPremium',
    label: t('isPremium')
  },
  {
    id: 'isOfficial',
    label: t('isOfficial')
  },
  {
    id: 'actions',
    label: ''
  }
]

const ClubsTable = ({ clubs, menuActions, lastElementRef }) => {
  const {t} = useTranslation();
  const rowElement = (club, index) => <ClubsTableRow
      key={club._id}
      lastElementRef={lastElementRef}
      club={club}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={clubs} columns={schema(t)} rowElement={rowElement}/>);
};

export default ClubsTable;
