import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyCIhe2DSU04othCylLdRidR6Lj2hfSH7JU",
  authDomain: "playerdex-app.firebaseapp.com",
  projectId: "playerdex-app",
  storageBucket: "playerdex-app.appspot.com",
  messagingSenderId: "578964583211",
  appId: "1:578964583211:web:04dfef3d4432b820228b6e",
  measurementId: "G-2C4T24WJJ1",
}

const isDevelopment =  process.env.REACT_APP_NODE_ENV === 'development'


const devFirebaseConfig = {
  apiKey: "AIzaSyAZbLVvesNX1ed7d6vmVGw6QIDKLckwP5o",
  authDomain: "playerdexdev.firebaseapp.com",
  projectId: "playerdexdev",
  storageBucket: "playerdexdev.appspot.com",
  messagingSenderId: "1083678184033",
  appId: "1:1083678184033:web:7a79c5b91528a1b692d020",
};

// Initialize Firebase
firebase.initializeApp(isDevelopment ? devFirebaseConfig : firebaseConfig);
// firebase.analytics();

export default firebase;
