import React from "react";
import Table from "../../../Components/Table/Table";
import UserClubTableRow from "./UserClubTableRow";

const schema = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'isPremium',
    label: 'Premium'
  },
  {
    id: 'official',
    label: 'Official'
  }
]

const UserClubTable = ({ data, menuActions = [], lastElementRef }) => {
  const rowElement = (dato, index) => <UserClubTableRow
      key={dato._id}
      lastElementRef={lastElementRef}
      data={dato}
      index={index}
      menuActions={menuActions}
  />

  return (<Table rows={data} columns={schema} rowElement={rowElement}/>);
};

export default UserClubTable;
