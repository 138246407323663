import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import SnackBar from "../Components/Common/SnackBar/SnackBar";
import {chatUtil} from "../utility/ChatUtils";
import {AuthContext, useAuthContext} from "./AuthContext";

const MessagesContext = createContext();
const { Provider } = MessagesContext;

const MessagesProvider = ({ children }) => {
    const auth = useAuthContext();

    const [latestUpdate, setLatestUpdate] = useState({})
    const [rooms, setRooms] = useState([])

    const [chatClient, setChatClient] = useState(null)
    const [selectedRoom, setSelectedRoom] = useState(null)
    const setup = async () => {
       return await chatUtil.connect().then(() => {
            setChatClient(chatUtil)
        })
    }

    const onSelectRoom = useCallback((roomId) => {
        setSelectedRoom(roomId)
    }, [])

    const chatConnect = () => {
       return setup()
    }

    const getLatestMessage = () => {
        chatClient.subscribeForMessages((conversation) => {
            setLatestUpdate(conversation)
        })
    }

    const createRoom = (roomName) => {
        if(chatClient) {
            return chatClient.createRoom(roomName)
        }
    }
    const addUserToRoom = (userId, roomId) => {
        if(chatClient) {
            return chatClient.addUserToRoom(userId, roomId)
        }
    }

    const removeUserFromRoom = (userId, roomId) => {
        if(chatClient) {
           return  chatClient.removeUserFromRoom(userId, roomId)
        }
    }

    const getRoomById = (roomId) => {
        return new Promise((resolve) => {
            chatClient.getRooms((roomsDetails) => {

                resolve(roomsDetails.find(roomDetails => {
                    return roomDetails.room?._id === roomId
                }))
            })
        })
    }

    const getRooms = () => {
        chatClient.getRooms(setRooms)
    }

    return (
        <Provider value={{chatClient, selectedRoom, onSelectRoom, createRoom, addUserToRoom, removeUserFromRoom, getLatestMessage, getRoomById, latestUpdate, chatConnect, getRooms, rooms}}>
            {children}
        </Provider>
    );
};


export const useMessages = () => {
    return useContext(MessagesContext)
}

export default MessagesProvider
