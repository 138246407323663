import React, { useContext, useState, useEffect } from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import TextFieldRenderer from "../../Components/Form/TextFieldRenderer";
import { css } from "@emotion/css";
import { REGISTER_USER_SA } from "./SuperAdminService";
import { SnackBarContext } from "../../context/SnackBarContext";
import { SEARCH_USER_WITH_MOBILE } from "../../graphql/userGraphql";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { useTranslation } from "../../context/Internationalization";
import DrawerFormTitle from "../../Components/Common/DrawerFormTitle/DrawerFormTitle";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";

const useStyles = makeStyles({
  phoneContainer: {
    height: "40px",
    width: "100%",
    padding: "8px 16px",
    background: colors.Base.White,
    border: `1px solid ${colors.Grey[300]}`,
    boxShadow: `0px 1px 2px rgba(22, 31, 37, 0.05)`,
    borderRadius: "8px",
  },
  phoneComponentName: {
    ...fonts.text.md.normal,
    color: colors.Grey[900],
  },
  phoneComponentNumber: {
    ...fonts.text.md.normal,
    color: colors.Grey[500],
  },
  sectionTitle: {
    ...fonts.text.sm.medium,
    color: colors.Grey[700],
  },
});

const DrawerFormStyles = css`
  .wrapper {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
  }
`;

const initialState = {
  firstName: "",
  lastName: "",
  mobile: "",
  role: "",
  clubId: "",
  teamId: "",
};

const AddUserToClubDrawer = ({
  openDrawer,
  onCloseDrawer,
  selectedClub,
  getClubs,
}) => {
  const { t } = useTranslation();
  const snackBar = useContext(SnackBarContext);
  const [filters, setFilters] = useState({ id: "", mobile: "" });
  const [user, setUser] = useState();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();

  useEffect(() => {
    if (filters.mobile?.length > 5) {
      resolveUser();
    }
  }, [filters.mobile]);

  const resolveUser = () => {
    if (filters.mobile) {
      axiosGraphQL({
        query: SEARCH_USER_WITH_MOBILE,
        variables: { mobile: filters.mobile },
      })
        .then((response) => {
            setUser(
              response?.data?.data?.searchUserWithMobile &&
                response?.data?.data?.searchUserWithMobile[0]
            );
        }).catch((error) => {
          errorLog(error, "AddUserToClubDrawer", "resolveUser", "SEARCH_USER_WITH_MOBILE");
          snackBar.setOptions({
              alertMessage: error.message,
              alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    }
  };

  const handleSubmit = () => {
    axiosGraphQL({
      query: REGISTER_USER_SA,
      variables: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        mobile: user?.mobile,
        role: "club-admin",
        clubId: selectedClub?._id,
      },
    })
      .then(async () => {
        snackBar.setOptions({
          alertMessage: t("club_admin_successfully_created"),
          alertSeverity: "success",
        });
        snackBar.setSnackbarOpen(true);
        getClubs();
        onCloseDrawer();
      }).catch((error) => {
        errorLog(error, "AddUserToClubDrawer", "handleSubmit", "REGISTER_USER_SA");
        snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  const onChangeFilter = ({ target: { name, value } }) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Drawer anchor="right" open={openDrawer} onClose={onCloseDrawer}>
      <Box className={DrawerFormStyles}>
        <Box className="wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton onClick={onCloseDrawer}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ maxWidth: "400px" }}>
            <DrawerFormTitle
              actionText={t("add")}
              className="grid-container-header"
              text={t("attach_club_admin")}
            />
            <Grid item xs={12}>
              <TextFieldRenderer
                label={t("mobile")}
                onChange={onChangeFilter}
                name={"mobile"}
                value={filters.mobile}
              />
            </Grid>
            {user && (
              <Grid item xs={12}>
                <PhoneComponents
                  name={`${user?.firstName} ${user?.lastName}`}
                  phone={user?.mobile}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: 24 }}>
              <Button onClick={handleSubmit} fullWidth>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

const PhoneComponents = ({ name, phone }) => {
  const styles = useStyles();
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      className={styles.phoneContainer}
    >
      <Grid container spacing={1}>
        <Grid item className={styles.phoneComponentName}>
          {name}
        </Grid>
        <Grid item className={styles.phoneComponentNumber}>
          {phone}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AddUserToClubDrawer;
