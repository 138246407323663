import {getFirebaseUser, getStorage} from "../auth/utils";
import {io} from "socket.io-client";
import {axiosGraphQL, sendMessage} from '../api/index';
import {
    ADD_USER_TO_ROOM,
    CREATE_ROOM,
    REMOVE_USER_FROM_ROOM,
    REMOVE_USER_TO_ROOM
} from "../pages/Messages/MessagesService";

export class ChatUtil {

    async getToken() {
        const user = await getFirebaseUser()
        if (user) {
            const token = await user.getIdToken(true)
            return token
        }
    }

    async updateConnection(token) {
        this.disconnect()

        this.socket = io(process.env.REACT_APP_BASE_URL, {
            reconnectionDelayMax: 10000,
            auth: {
                token
            },
            path: '/chat/chat'
        });
        this.connected = true

    }

    async connect() {
        if(!this.connected) {
            this.disconnect()
            const user = await getFirebaseUser();

            if (user) {
                const token = await user.getIdToken(true);
                getStorage().setItem("tokenLastValidity", Date.now().toString());
                getStorage().setItem("token", token);
                this.socket = io(process.env.REACT_APP_BASE_URL, {
                    reconnectionDelayMax: 10000,
                    auth: {
                        token
                    },
                    path: '/chat/chat'
                });
                this.connected = true
            }
        }
    }

    getRooms(callback) {
        if(this.socket) {
            this.socket.emit("getRooms")
            this.socket.off('myRooms')

            return this.socket.on("myRooms", (response) => {
                callback(response)
            })
        }

    }
    getConversation({roomId, skip = 0, limit = 20}, callback) {

        this.socket.emit("getConversation", {roomId, skip: skip * limit, limit:  limit})
        this.socket.off('conversation')

        return  this.socket.on("conversation", (response) => {
                 callback(response)
            })
    }



    subscribeForMessages = (callback) => {
        if(this.socket) {
            this.socket.emit('subscriptions');

            this.socket.on('subscriptions', response => {
                response.subscriptions?.forEach(userId => {
                    if (!userId) {
                        return
                    }
                    this.socket.off(userId)

                    this.socket.on(userId, res => {
                        callback && callback(res)
                    });
                });
            });
        }

    };

    sendMessage = (message) => {
       return  sendMessage(message)
    }

    disconnect() {
        if(this.socket && this.connected) {
            this.socket.disconnect()
            this.connected = false

        }
    }
    createRoom = (name) => {
        return axiosGraphQL({
            query: CREATE_ROOM,
            variables: {name},
        }).then((response) => {
            return response?.data?.data?.createRoom
        }).catch((error) => {
			throw error;
        });

    }
    addUserToRoom = (userId, roomId) => {
        return axiosGraphQL({
            query: ADD_USER_TO_ROOM,
            variables: {userId, roomId},
        }).then((response) => {
            return response?.data?.data?.addUserToRoom
        }).catch((error) => {
			throw error;
        });
    }
    removeUserFromRoom = (userId, roomId) => {
        return axiosGraphQL({
            query: REMOVE_USER_FROM_ROOM,
            variables: {userId, roomId},
        }).then((response) => {
            return response?.data?.data?.removeUserFromRoom
        }).catch((error) => {
			throw error;
        });
    }
}


export const chatUtil =  new ChatUtil()
